import { createContext,useState,useEffect } from "react";
import l1 from './Assets/Home-Page/HERO/1.jpg'
import l2 from './Assets/Home-Page/HERO/2.jpg'
import l3 from './Assets/Home-Page/HERO/3.jpg'
import axios from 'axios'
import l1Mobile from './Assets/Home-Page/png khand gur shakkar/gur png.png'
import l2Mobile from './Assets/Home-Page/png khand gur shakkar/khand png.png'
import l3Mobile from './Assets/Home-Page/png khand gur shakkar/shakkar png.png'

export const AppContext=createContext({
    slides:[],
    currentIndex:(null),
    products:[]

})

const AppProvider=({children})=>{
    const [currentIndex, setCurrentIndex] = useState(1);
    const [products,setProducts]=useState([])
    const [blogs, setBlogs] = useState([]);
    const [filteredBlogs, setFilteredBlogs] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [loginStatus,setLoginStatus]=useState(false)
    const [slides,setSlides]=useState([])
    const [loading,setLoading]=useState(false)
    const [sales,setSales]=useState([])

     const BACKEND_URL=process.env.REACT_APP_BACKEND_URL
//     const slides=[
        
//     {
//         id:3,
//         title:'Zero Chemicals, Natural Sweeteners',
//         imgSrc:l3,
//         mobileImgSrc:l3Mobile,
//         description:'100% chemical-free, Discover the natural taste of Aryadhenu Shudh Desi Shakkar and bring natural sweetness to your dishes.',
        
//     },
//     {
//         id:1,
//         title:'One & Only Substitute to Sugar',
//         imgSrc:l1,
//         mobileImgSrc:l1Mobile,
//         description:'The Healthiest & Sulfur-free Substitute for Sugar. Khand is the smart choice over refined Sugar.',
        
//     },
//     {
//         id:2,
//         title:'Pure Sweetness, Pure Energy',
//         imgSrc:l2,
//         mobileImgSrc:l2Mobile,
//         description:'The perfect blend of taste, nutrition, and purity, Add Aryadhenu Shudh Desi Gur to your diet to add nutrition to your body.',
        
//     }
    
    
//   ]

  useEffect(()=>{
    const fetchSlides=async()=>{
       setLoading(true)
        try{
            const response=await axios.get(`${BACKEND_URL}/slides/get-slides`)
            const slidesArray=Object.values(response.data)
            setSlides(slidesArray)
            console.log(slidesArray)
        }catch(err){

        }
        finally{
          setLoading(false)
        }
    }

    fetchSlides()
  },[])

  useEffect(()=>{
        setLoading(true)
        const fetchProducts=async()=>{
            try{
              const response=await axios.get(`${BACKEND_URL}/getProduct`)

              if(response.status===200 || response.status===201){
                const data=response.data
                setProducts(data)
                sessionStorage.setItem('products',JSON.stringify(data))
            

              }else{
             
              }
            }
            catch(error){
                
            }
        }
        const storedProducts=sessionStorage.getItem('products')
        if(storedProducts){
          setProducts(JSON.parse(storedProducts))
        }else{
         fetchProducts()
        }
        setLoading(false)
        
     },[])

   useEffect(() => {
        
        const fetchBlogs = async () => {
            const response = await axios.get(`${BACKEND_URL}/api/blogs`);
            setBlogs(response.data);
            // Extract unique categories
            const uniqueCategories = [...new Set(response.data.map(blog => blog.category))];
            setCategories(uniqueCategories);
        };
        fetchBlogs();
    }, []);

    useEffect(() => {
        const fetchFilteredBlogs = async () => {
            const response = await axios.get(`${BACKEND_URL}/api/blogs`, {
                params: { search: searchTerm, category: selectedCategory }
            });
            setFilteredBlogs(response.data);
        };
        fetchFilteredBlogs();
    }, [searchTerm, selectedCategory]);

    useEffect(()=>{
      const fetchSales=async()=>{
        const response=await axios.get(`${BACKEND_URL}/sale/get-active-sales`)
        
        if(response.data){
          setSales(response.data)
        }
      }

      fetchSales()
    },[])

    console.log(sales,'sales information')

    const getActiveSaleForProduct = (productId) => {
    
    
    const activeProductSale =  sales.find(
      (sale) =>
        sale.type === "product" &&
        sale.productId === productId &&
        new Date(sale.startDate) <= new Date() &&
        new Date(sale.endDate) >= new Date()
    )
    
    

    // Check for any global sale
    const activeGlobalSale = sales.find(
      (sale) =>
        sale.type === "global" &&
        new Date(sale.startDate) <= new Date() &&
        new Date(sale.endDate) >= new Date()
    );

    // Return either product-specific sale or global sale if applicable
    console.log(activeProductSale,'active sale')
    return activeProductSale || activeGlobalSale;
  };

  const value={slides,currentIndex,setCurrentIndex,products,setProducts,blogs,setBlogs,filteredBlogs,setFilteredBlogs,
    searchTerm,setSearchTerm,selectedCategory,setSelectedCategory,setCategories,categories,loginStatus,setLoginStatus,loading,sales,getActiveSaleForProduct
  }

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    )
}

export default AppProvider