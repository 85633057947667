// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .distributor-form{
    padding:200px;
    
    display: flex;
    justify-content: center;
 
}

.form{
    width:60vw;
    display: flex;
    flex-direction: column;
    text-align: left;
}

label{
    font-size: larger;
}

.form input{
    height:50px;
    font-size: medium;
}

fieldset{
    display: flex;
    flex-direction:column;
}

.radio p{
    font-size: large;
}
.options{
    display: flex;
    flex-direction: column;
    
}

.option {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    font-size: medium;
}

.option label{
    margin-left: 20px;
}

.option input{
    width:20px
}

.text-question p{
   font-size: large;
}
.text-question input{
    width:80%;
} */`, "",{"version":3,"sources":["webpack://./src/Styles/DistributorForm.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GA2DG","sourcesContent":["/* .distributor-form{\n    padding:200px;\n    \n    display: flex;\n    justify-content: center;\n \n}\n\n.form{\n    width:60vw;\n    display: flex;\n    flex-direction: column;\n    text-align: left;\n}\n\nlabel{\n    font-size: larger;\n}\n\n.form input{\n    height:50px;\n    font-size: medium;\n}\n\nfieldset{\n    display: flex;\n    flex-direction:column;\n}\n\n.radio p{\n    font-size: large;\n}\n.options{\n    display: flex;\n    flex-direction: column;\n    \n}\n\n.option {\n    display: flex;\n    flex-direction: row;\n    justify-content: left;\n    align-items: center;\n    font-size: medium;\n}\n\n.option label{\n    margin-left: 20px;\n}\n\n.option input{\n    width:20px\n}\n\n.text-question p{\n   font-size: large;\n}\n.text-question input{\n    width:80%;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
