


import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import '../Styles/Careers.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

 const BACKEND_URL=process.env.REACT_APP_BACKEND_URL

function Careers() {
  const [selectedJobId, setSelectedJobId] = useState(''); // Store only the selected job ID
  const [jobs, setJobs] = useState([]); // Fetch jobs dynamically
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/get-jobs`);
        setJobs(response.data); // Assuming response.data is an array of job objects
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };
    fetchJobs();
  }, []);

  const handleChange = (event) => {
    setSelectedJobId(event.target.value);
  };

  // Find the selected job object based on the selected ID
  const selectedJob = jobs.find((job) => job._id === selectedJobId);

  return (
    <div className="careers-container">
      <div className="page-info">
        <h1>Careers</h1>
        <div className="page-info-navigation">
          <div
            className="page-info-navigation-icons"
            onClick={() => navigate('/')}
          >
            <FontAwesomeIcon icon={faHouse} />
          </div>
        </div>
      </div>

      <div className="careers">
        <h1>Careers</h1>
        <p>
          Looking for a company that offers a challenging role, a dynamic
          environment, and abundant career growth opportunities? Welcome to
          Aryadhenu Natural Diet Pvt Ltd. At Aryadhenu, we are committed to
          fostering a culture of innovation and excellence. Our dynamic work
          environment encourages creativity and collaboration, allowing you to
          take on meaningful projects that make a real impact. We believe in
          nurturing talent and providing continuous learning and development
          opportunities. Whether you're just starting your career or looking to
          advance, Aryadhenu offers the platform to build a rewarding and
          successful professional journey. Join us and be part of a legacy of
          quality and innovation.
        </p>
      </div>

      <div className="careers-dropdown">
        <h2>All our open Positions:</h2>
        <select onChange={handleChange} value={selectedJobId}>
          <option value="">Select a job</option>
          {jobs.map((job) => (
            <option key={job._id} value={job._id}>
              {job.title}
            </option>
          ))}
        </select>
      </div>

      <div className="job-description">
        {selectedJob && (
          <div>
            <p>
              <strong>Title:</strong> {selectedJob.title}
            </p>
            <p>
              <strong>Company:</strong> {selectedJob.company}
            </p>
            <p>
              <strong>Location:</strong> {selectedJob.location}
            </p>
            {selectedJob.description.map((para, index) => (
              <p key={index}>{para}</p>
            ))}
            <button
              className="apply-button"
              onClick={() => {
                navigate('/apply');
              }}
            >
              Apply Now
            </button>
          </div>
        )}
      </div>

      <Navbar isHomePage={false} />
    </div>
  );
}

export default Careers;
