import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../Styles/userDashboard.css'
import Navbar from './Navbar';
import dummyUser from '../Assets/user-dummy.png'
import AddAddress from '../Components/AddAddress.js'
import AlertPopup from './AlertPopup.js';

const isTokenValid = () => {
    const token = localStorage.getItem('token');
    const expiry = localStorage.getItem('tokenExpiry');
    return token && new Date().getTime() < expiry;
};

function UserDashboard() {
    const [orderDetails, setOrderDetails] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state?.email || localStorage.getItem('email'); // Retrieve email from navigation state
    const [activeSection, setActiveSection] = useState('orderDetails');
    const [isAddAddressOpen, setIsAddAddressOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [imageUrl,setImageUrl]=useState('');
    const [isLoading,setIsLoading]=useState(false)
    const BACKEND_URL=process.env.REACT_APP_BACKEND_URL
    const [addressUpdateFlag, setAddressUpdateFlag] = useState(false);
    const [cancellationRequests, setCancellationRequests] = useState([])
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [sortDirection, setSortDirection] = useState('desc');
    const [alertMessage,setAlertMessage]=useState('')
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (!isTokenValid()) {
            setAlertMessage('Session expired. Please log in again.');
            navigate('/login');
            return;
        }
        
        const fetchOrderDetails = async () => {
            setIsLoading(true)
            
            try {
                const response = await axios.post(`${BACKEND_URL}/get-order-details`, {
                    email:email
                });
                const sortedOrders = response.data.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));
                setOrderDetails(sortedOrders);

            } catch (error) {
                console.error('Failed to fetch order details:', error);
            }finally{
                setIsLoading(false)
            }
        };
        
        fetchOrderDetails();

        

        const tokenExpiryHandler = setInterval(() => {
            if (!isTokenValid()) {
                clearInterval(tokenExpiryHandler);
                setAlertMessage('Session expired. Redirecting to login.');
                navigate('/login');
            }
        }, 60000);

        return () => clearInterval(tokenExpiryHandler);
    }, [navigate]);

   


    useEffect(()=>{
        const fetchCancellationRequests = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/get-cancellation-request`,);
                setCancellationRequests(response.data.map((request) => request.orderId));
            } catch (error) {
                console.error('Failed to fetch cancellation requests:', error);
            }
        };
        fetchCancellationRequests();
    },[])

    const handleCancellationRequest = async (orderId) => {
        try {
            await axios.post(`${BACKEND_URL}/cancellation-request`, { email, orderId });
            setCancellationRequests((prev) => [...prev, orderId]);
        } catch (error) {
            console.error('Failed to send cancellation request:', error);
        }
    };
    const renderCancellationField = (order) => {
        if (order.orderStatus === 'PICKUP SCHEDULED') {
            return cancellationRequests.includes(order.orderId) ? (
                'Cancellation Requested'
            ) : (
                <button onClick={() => handleCancellationRequest(order.orderId)}>Request Cancel</button>
            );
        } else if (order.orderStatus === 'CANCELLED') {
            return 'Cancelled';
        } else {
            return 'Cancellation Not Allowed at This Stage';
        }
    };
    const formatDateToIndian = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-IN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };

    const handleImageChange=async(event)=>{
       const file=event.target.files[0];
       if(file){
        const reader=new FileReader()
         reader.onloadend = () => {
                setImageUrl(reader.result);
                axios.post(`${BACKEND_URL}/update-profile-picture`,{email:email,imageUrl:reader.result})
            };
            reader.readAsDataURL(file);  
       }

    }

    useEffect(() => {
        if (!isTokenValid()) {
            setAlertMessage('Session expired. Please log in again.');
            navigate('/login');
            return;
        }

        fetchAddresses();

        const tokenExpiryHandler = setInterval(() => {
            if (!isTokenValid()) {
                clearInterval(tokenExpiryHandler);
                setAlertMessage('Session expired. Redirecting to login.');
                navigate('/login');
            }
        }, 60000);

        return () => clearInterval(tokenExpiryHandler);
    }, [navigate, addressUpdateFlag]);

    const fetchAddresses = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/api/addresses/${email}`);
            setAddresses(response.data);
        } catch (error) {
            console.error('Failed to fetch addresses:', error);
        }
    };

    const handleAddOrEditAddress = async (address) => {
        console.log(address,'address in the edit')
        const body={address}
        try {
            if (isEditMode) {
                await axios.put(`${BACKEND_URL}/api/addresses/${email}/${selectedAddress._id}`, 
                    body,
                    { headers: { 'Content-Type': 'application/json' } }
                );
            } else {
                await axios.post(`${BACKEND_URL}/api/addresses`, { email, address });
            }
            // fetchAddresses(); // Refresh the list
            // setIsAddAddressOpen(false);
             setAddressUpdateFlag((prev) => !prev); // Toggle flag to trigger re-fetch
            setIsAddAddressOpen(false);
            setIsEditMode(false);
            setSelectedAddress(null);
        } catch (error) {
            console.error('Failed to save address:', error);
        }
    };

    const handleDeleteAddress = async (addressId) => {
        try {
            await axios.delete(`${BACKEND_URL}/api/addresses/${email}/${addressId}`);
            // fetchAddresses();
            setAddressUpdateFlag((prev) => !prev); 
            setSelectedAddress(null)
            setIsAddAddressOpen(false)
            setAlertMessage('Address Deleted')
            
        } catch (error) {
            console.error('Failed to delete address:', error);
        }
    };

    const openAddAddressPopup = () => {
        setIsEditMode(false);
        setSelectedAddress(null);
        setIsAddAddressOpen(true);
    };

    const openEditAddressPopup = (address) => {
        setIsEditMode(true);
        setSelectedAddress(address);
        setIsAddAddressOpen(true);
    };

    const handleLogout=()=>{
        navigate('/logout')
    }

    // Handle Search Filter
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    // Apply filter based on search term
    useEffect(() => {
        const filtered = orderDetails.filter((order) =>
            order.orderId.toLowerCase().includes(searchTerm.toLowerCase()) ||
            order.emailAddress.toLowerCase().includes(searchTerm.toLowerCase()) ||
            order.orderStatus.toLowerCase().includes(searchTerm.toLowerCase()) ||
            order.products.some((product) => 
            product.productName.toLowerCase().includes(searchTerm.toLowerCase())
        ) 
            
        );
        setFilteredOrders(filtered);
    }, [searchTerm, orderDetails]);

    // Manual Sort (Sort by order date)
    // const sortByDate = () => {
    //     const sortedOrders = [...filteredOrders].sort((a, b) => new Date(a.orderDate) - new Date(b.orderDate));
    //     setFilteredOrders(sortedOrders);
    // };


     const toggleSortByDate = () => {
        const sorted = [...filteredOrders].sort((a, b) => {
            return sortDirection === 'desc'
                ? new Date(a.orderDate) - new Date(b.orderDate)
                : new Date(b.orderDate) - new Date(a.orderDate);
        });
        setFilteredOrders(sorted);
        setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc'); // Toggle the sorting direction
    };

    // Pagination logic
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const currentOrders = filteredOrders.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );


    return (
        <div className="dashboard">
            {isLoading?
            <div className='loader'></div>
            :(
                <>
            <aside className="sidebar">

            
                <button onClick={() => setActiveSection('orderDetails')}>Orders</button>
                <button onClick={()=>setActiveSection('profile')}>Profile</button>
                <button onClick={()=>setActiveSection('manageAddresses')}>Addresses</button>
                <button onClick={handleLogout}>Logout</button>
            </aside>
            <AlertPopup message={alertMessage} onClose={() => setAlertMessage('')} />
            <main className="dashboard-content">
                {
                    activeSection==='profile' && (
                        <div className='profile-information'>
                            <h2>Profile</h2>
                            <table className='profile-information-table'>
                                <thead>
                                    <tr>
                                        <td>Profile Picture</td>
                                        {/* <td>Username</td> */}
                                        <td>Email</td>
                                        <td>Phone Number</td>
                                        {/* <td>Billing Address</td>
                                        <td>Shipping Address</td> */}
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    <tr >
                                        <td style={{display:'flex',flexDirection:'column',cursor: 'pointer'}}>
                                            <img src={imageUrl || localStorage.getItem('user-picture') || dummyUser } alt='Profile Picture' style={{cursor: 'pointer'}}  onClick={() => document.getElementById('fileInput').click()}></img>
                                        
                                        

                                          <input
                                            id="fileInput"
                                            type="file"
                               
                                            onChange={handleImageChange}
                                            style={{ display: 'none' }} // Hide the file input
                                        />

                                        </td>
                                        {/* <td>{localStorage.getItem('username') || ''}</td> */}
                                        <td>{localStorage.getItem('email') || ''}</td>
                                        <td>{localStorage.getItem('mobileNumber') || ''}</td>
                                       
                                    </tr>
                                
                                </tbody>
                            </table>
                        </div>
                    )
                }
                {activeSection === 'orderDetails' && (
                    <>
                    <h2>Order Details</h2>
                    <div className='dashboard-filter-container'>
                    <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearch}
                            placeholder="Search by Order ID, Email, Status or Product"
                            className="search-input"
                        />
                        <button onClick={toggleSortByDate}>
                        Sort by Date ({sortDirection === 'desc' ? 'Newest First' : 'Oldest First'})
                        </button>
                    </div>
                    <div className="table-container">
                        
                        <table className="ordered-table">
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Status</th>
                                    <th>Email</th>
                                    <th>Payment Status</th>
                                    <th>Shipping Address</th>
                                    <th>Ordered Date</th>
                                    <th>Transaction ID</th>
                                    <th>Tracking Number</th>
                                    <th>Total Amount</th>
                                    <th>Discount Applied</th>
                                    <th>Products</th>
                                    <th>Cancel Request</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                {currentOrders.map((order, index) => (
                                    <tr key={index}>
                                        <td>{order.orderId}</td>
                                        <td>{order.orderStatus}</td>
                                        <td>{order.emailAddress}</td>
                                        <td>{order.paymentStatus}</td>
                                        <td>{order.shippingAddress}</td>
                                        <td>{formatDateToIndian(order.orderDate)}</td>
                                        <td>{order.transactionId}</td>
                                        <td>{order.trackingNumber}</td>
                                        <td> {order.totalAmount}</td>
                                        <td>{order.discounts}</td>
                                        <td>
                                            {order.products.map((product) => (
                                                <div key={product.id}>{product.productName} x {product.quantity}</div> // Assuming each product has a unique 'id'
                                            ))}
                                        </td>
                                        <td>{renderCancellationField(order)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        
                    </div>
                    <div className="pagination">
                            <button
                                className='page-button'
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            <span className='page-info'>
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                className='page-button'
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                        </div>
                    </>
                )}
                <div className='address-section'>
                {activeSection === 'manageAddresses' && (
                    
                    <div className="manage-addresses">
                        <h2>Manage Addresses</h2>
                        
                        <div className="address-dropdown">
                                <select
                                onChange={(e) => {
                                const selectedId = e.target.value;
                                const selected = addresses.find((addr) => addr._id === selectedId);
                                setSelectedAddress(selected || null);
                                }}
                                value={selectedAddress?._id || ""}
                                >
                                <option value="" disabled>
                                Select an Address
                                </option>
                                    {addresses.map((address) => (
                                    <option key={address._id} value={address._id}>
                                    {address.firstName} {address.lastName} - {address.streetAddress}
                                </option>
                                ))}
                                </select>
                                </div>

   
                                {selectedAddress && (
                                <div className="selected-address-card">
                                <h3>Selected Address</h3>
                                <p>{selectedAddress.firstName} {selectedAddress.lastName}</p>
                                <p>
                                {selectedAddress.streetAddress}, {selectedAddress.townCity}, {selectedAddress.state},{" "}
                                {selectedAddress.pincode}
                                 </p>
                                <p>Phone: {selectedAddress.phone}</p>
                                 <div className="card-actions">
                                <button onClick={() => openEditAddressPopup(selectedAddress)}>Edit</button>
                                <button onClick={() => handleDeleteAddress(selectedAddress._id)}>Delete</button>
                                </div>
        </div>
    )}
                        <button onClick={openAddAddressPopup} className='checkout-container-button'>Add New Address</button>
                    </div>
                )}
                {/* Add Address Popup */}
                {activeSection==='manageAddresses' && isAddAddressOpen && (
                    <div className="popup-overlay">
                        <div className="popup-content">
                            <AddAddress 
                                onClose={() => setIsAddAddressOpen(false)}
                                onSave={handleAddOrEditAddress}
                                initialData={isEditMode ? selectedAddress : null} 
                            />
                        </div>
                    </div>
                )}
                </div>
               
            </main>
            </>
            )}
            <Navbar isHomePage={false}/>
        </div>
    );
}

export default UserDashboard;
