import React, { useContext,useEffect,useState } from 'react'

import Logo from '../Assets/Aryadhenu_Logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faMagnifyingGlass,faUser,faCartShopping,faAngleDown} from '@fortawesome/free-solid-svg-icons'
import '../Styles/Navbar.css'
import { AppContext } from '../AppContext'
import { useCart } from '../CartContext'
import defaultLogo from '../Assets/default-logo.png'
import '../Styles/Navbar.css'
import { useNavigate } from 'react-router-dom'
import dummyImage from '../Assets/user-dummy.png'


import { Link } from 'react-router-dom'

const isTokenValid = () => {
    const token = localStorage.getItem('token');
    const expiry = localStorage.getItem('tokenExpiry');
    return token && new Date().getTime() < expiry;
};

const DesktopNavbar=({isHomePage}) =>{

    const {slides,currentIndex}=useContext(AppContext)
   

    // const color=slides[currentIndex].backgroundColor;
    const { getCartItemCount ,cart} = useCart();
    const cartItemCount = getCartItemCount();
    const [navbarStyle, setNavbarStyle] = useState({});
    const [isScrolled, setIsScrolled] = useState(false);
    const { loginStatus } = useContext(AppContext);
    const navigate = useNavigate();

    const handleNavigate = () => {
        // If logged in, navigate to logout; otherwise, navigate to login
        // navigate(loginStatus ? '/logout' : '/login');
        if(isTokenValid()){
          navigate('/logout')
        }else{
          navigate('/login')
        }
    };
    
    const handleLogoNavigate=()=>{
      navigate('/')
    }

    const userPicture = localStorage.getItem('user-picture');



 useEffect(() => {
    const handleScroll = () => {
      const heroElement = document.querySelector('.hero-sample');
      if (heroElement) {
        const heroHeight = heroElement.offsetHeight;
        const scrollPosition = window.scrollY;
        const threshold = 50; 

        if (scrollPosition >  threshold) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
    

 
    
    
  return (
    <>
    <div className='marquee'>
      <div className='marquee-content'>
      <p>Delivering to PAN India at reasonable shipping prices</p>
      </div>
    </div>
    <div style={navbarStyle} className={`navbar ${isHomePage ? 'home' : ''}${isScrolled ? 'scrolled' : ''}`} >
        <div className='logo'>
            <img src={defaultLogo } alt='aryadhenu-logo' className='logo-image' id='default-logo' onClick={handleLogoNavigate}/>
            <img src={Logo} alt='aryadhenu-logo' className='logo-image' id='hover-logo' onClick={handleLogoNavigate}/>
            


        </div>
        <div className='navbar-links'>
        <div className='nav-links'>
            <ul>
                <li><Link to='/'>Home</Link></li>
                <li><Link to='/shop'>Shop</Link></li>
                <li><Link to='/about'>About</Link></li>
                <li><Link to='/contact'>Contact</Link></li>
                <li className='dropdown'><Link>Distributor<FontAwesomeIcon icon={faAngleDown} className='fontawesome-icon' /></Link>
                     <div className="dropdown-content">
                     <Link to="/distributor/candf-agents-only">C & F agents only</Link>
                     <Link to="/distributor/candf-agents">C & F agents</Link>
                     <Link to="/distributor/super-stockist">Super Stockist</Link>
                     <Link to="/distributor/dealer">Dealers</Link>
                     </div>
                </li>
            </ul>

        </div>
        <div className='nav-icons'>
            <FontAwesomeIcon icon={faMagnifyingGlass}  className='fontawesome-icon' />
            <div className='dropdown'>
            <FontAwesomeIcon icon={faUser}  className='fontawesome-icon'  />
               <div className="dropdown-content" style={{padding:'10px'}}>
                {isTokenValid()?<>
                     {userPicture && <img src={userPicture}/>}
                     <p>{localStorage.getItem('email')}</p>
                     <p>{localStorage.getItem('mobileNumber')}</p>
                     <p>{localStorage.getItem('user-name')}</p>
                    
                     <Link to='/user-dashboard'>Update Profile</Link>
                     <Link to='/logout'>Logout</Link>
                    
                </>:(
                  <>
                    <Link to="/login">Login</Link>
                    <Link to="/signup">Create Account</Link>
                    
                  </>
                )}
                     
                     </div>
            </div>
            
            <Link to='/cart' style={{textDecoration:'none'}}>
            <FontAwesomeIcon icon={faCartShopping}  className='fontawesome-icon'  />
            {cart.length>0 && (
            <span>{cartItemCount}</span>
            )}
             
            </Link>

        </div>
        </div>
    </div>
    </>
  )
}

export default DesktopNavbar