// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-and-conditions-container {
    padding: 20px 10%;
    padding-top: 130px;
}

.terms-and-conditions-container h1 {
    text-align: left;
    color: #E42727;
    font-family: 'Raleway', sans-serif;
    letter-spacing: 1px;
}

.terms-and-conditions-container p{
    text-align: left;
        /* color: #444; */
        font-family: 'Poppins', sans-serif;
        letter-spacing: 1px;
}

.terms-and-conditions h2 {
    text-align: left;
    color: #E42727;
    font-family: 'Raleway', sans-serif;
    letter-spacing: 1px;
}

.terms-and-conditions p {
    text-align: left;
    /* color: #444; */
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;


}

.terms-and-conditions li {
    text-align: left;
    /* color: #444; */
    font-family: 'Poppins', sans-serif;
    letter-spacing: 1px;

}

@media screen and (max-width:768px){
    .terms-and-conditions-container {
            padding: 20px 10%;
            padding-top: 180px;
        }
}`, "",{"version":3,"sources":["webpack://./src/Styles/TermsandConditions.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,kCAAkC;IAClC,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;QACZ,iBAAiB;QACjB,kCAAkC;QAClC,mBAAmB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,kCAAkC;IAClC,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,kCAAkC;IAClC,mBAAmB;;;AAGvB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,kCAAkC;IAClC,mBAAmB;;AAEvB;;AAEA;IACI;YACQ,iBAAiB;YACjB,kBAAkB;QACtB;AACR","sourcesContent":[".terms-and-conditions-container {\n    padding: 20px 10%;\n    padding-top: 130px;\n}\n\n.terms-and-conditions-container h1 {\n    text-align: left;\n    color: #E42727;\n    font-family: 'Raleway', sans-serif;\n    letter-spacing: 1px;\n}\n\n.terms-and-conditions-container p{\n    text-align: left;\n        /* color: #444; */\n        font-family: 'Poppins', sans-serif;\n        letter-spacing: 1px;\n}\n\n.terms-and-conditions h2 {\n    text-align: left;\n    color: #E42727;\n    font-family: 'Raleway', sans-serif;\n    letter-spacing: 1px;\n}\n\n.terms-and-conditions p {\n    text-align: left;\n    /* color: #444; */\n    font-family: 'Poppins', sans-serif;\n    letter-spacing: 1px;\n\n\n}\n\n.terms-and-conditions li {\n    text-align: left;\n    /* color: #444; */\n    font-family: 'Poppins', sans-serif;\n    letter-spacing: 1px;\n\n}\n\n@media screen and (max-width:768px){\n    .terms-and-conditions-container {\n            padding: 20px 10%;\n            padding-top: 180px;\n        }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
