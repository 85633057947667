import React from 'react'
import '../Styles/ShippingPolicy.css'
import Navbar from './Navbar'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faGreaterThan} 
from '@fortawesome/free-solid-svg-icons'

function ShippingPolicy() {

    const navigate=useNavigate()
  return (
    <>
    <div className='shipping-policy-container'>
         <div className='page-info'>
            <h1>Shipping Policy</h1>
            <div className='page-info-navigation'>
                <div className='page-info-navigation-icons' onClick={()=>{navigate('/')}}><FontAwesomeIcon icon={faHouse}></FontAwesomeIcon></div>
                
                
            </div>
            </div>
        <div className='shipping-policy'>
            <h1>Shippping Policy</h1>
            <p>At Aryadhenu, we strive to ensure a seamless and timely delivery experience for our valued customers. 
                Please take a moment to review our shipping guidelines to familiarize yourself with the process.</p>
        </div>
        <div className='shipping-policy'>
            <h2>Order Processing</h2>
            <p>Once your order is placed, our team begins processing it with care. We aim to dispatch all orders within 6 business days.
                 Please note that orders placed after 6 will be processed on the next business day. After processing your order it
                 will rreach you within 10 to 15 working days. 
                In case you dont recieve the order please contact customer care for refund.</p>
        </div>
        <div className='shipping-policy'>
            <h2>Tracking Your Order</h2>
            <p>Once your order is shipped, you will receive a shipping confirmation email 
               containing a tracking number and instructions on how to track your package’s journey to your doorstep.</p>
        </div>
        <div className='shipping-policy'>
            <h2>Delivery Address</h2>
            <p>To ensure a successful delivery, please double-check the accuracy of the shipping address you provide during checkout. 
                If you need to make any changes,kindly contact our customer service team at customercare@aryadhenu.com /+91 9467843826 as soon as possible.</p>
        </div>
        <div className='shipping-policy'>
            <h2>Package Delivery</h2>
            <p>If your tracking information indicates that your package has been delivered but you cannot locate it,
                 we recommend the following steps:</p>
            <ul>
                <li>Verify the shipping address in your order details.
                </li>
               <li>Check for any attempted delivery notices.</li>
               <li>Look around the delivery location, including your mailbox.</li>
               <li>Inquire if someone else accepted the delivery on your behalf.</li>
               <li>Allow an additional 48 hours, as rare instances may lead to a delay between the “delivered” status and actual arrival.</li>
            </ul>
        </div>
        <div className='shipping-policy'>
            <h2>Contact Us</h2>
            <p>If you haven’t received your package within the expected time frame or have any concerns about your delivery, 
                please don’t hesitate to contact our customer service team within 30 days of the expected delivery date. 
                We’re here to assist you and ensure a satisfactory resolution.</p>
            <p>Our Head Office-<b> Hisar, Haryana,125001</b></p>
        </div>

        
    </div>
    <Navbar isHomePage={false}/>
    </>
  )
}

export default ShippingPolicy