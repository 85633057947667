import React, { useContext ,useEffect,useState} from 'react';
import { AppContext } from '../../AppContext';
import { useParams } from 'react-router-dom';
import '../../Styles/BlogItem.css'
import Navbar from '../Navbar';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faGreaterThan} 
from '@fortawesome/free-solid-svg-icons'

const BlogItem = () => {
    const {blogs}=useContext(AppContext)
    const { id } = useParams();
    const blog = blogs.find((b) => b._id === id);
    const [loading,setLoading]=useState(true)
    const navigate=useNavigate()

    useEffect(() => {
        // Simulate a delay to wait for data
        const timer = setTimeout(() => {
            setLoading(false); // Set loading to false after the delay
        }, 1000); // Adjust the time as needed (1000 ms = 1 second)

        return () => clearTimeout(timer); // Cleanup the timer on unmount
    }, []);

    const navigateBack=()=>{
        navigate(-1)
    }
     

    
    return (
        <>
        <div className='blog-item-container'>
            <div className='page-info'>
            
            <div className='page-info-navigation'>
                <div className='page-info-navigation-icons' onClick={()=>{navigate('/')}}><FontAwesomeIcon icon={faHouse}></FontAwesomeIcon></div>
                
                <h5>Blogs</h5>
            </div>
            </div>
            
            
            {loading ? 
                <div className='loader'></div>
            :(
            
            <div className='blog-item'>
             
             <>
             
            <h2>{blog.title}</h2>
            <p>by: {blog.author} on {new Date(blog.datePosted).toLocaleDateString()}</p>
            <img src={blog.image} alt={blog.title} />
            {blog.content.map((section, index) => (
                <div key={index}>
                    <h3>{section.subheading}</h3>
                    {section.paragraphs.map((paragraph, pIndex) => (
                        <p key={pIndex}>{paragraph}</p>
                    ))}
                </div>
            ))}
            <button onClick={navigateBack} className='blog-details-back-btn'>Back</button>
            </>
            
            </div>
            )}
            
            

            
        </div>
        <Navbar isHomePage={false}/>
        </>
    );
};

export default BlogItem;
