import React ,{useState,useEffect, useContext}from 'react'
import { useNavigate } from 'react-router-dom';

import '../../Styles/Home.css'

import Boora from '../../Assets/Products/Boora-330gm.png';
import Gur from '../../Assets/Products/Gur-900gm.png';
import Khand from '../../Assets/Products/Khand-900gm.png'
import Mustard from '../../Assets/Products/Mustard-oil2.png'
import Shakkar from '../../Assets/Products/Shakkar-2.png'



// import profilePic from '../../Assets/sample-profile-pic.png'

import {faCartShopping,faArrowRightLong,faArrowLeftLong,faIndianRupeeSign,faTruckFast,faHeadset} 
from '@fortawesome/free-solid-svg-icons'
import Hero from './Hero';
import Navbar from '../Navbar';
import MakingProcess from './MakingProcess';
import Ghee from '../../Assets/website 500px ghee.jpg'
import Khand500 from '../../Assets/khand500.png'

import Products from './Products';

import Advantages from './Advantages';
import family from '../../Assets/Home-Page/family 1920.png'
import Reviews from './Reviews';
import { AppContext,loading } from '../../AppContext';
import BlogPagination from './BlogPagination';





const products=[{id:0,image:Boora,title:'Shudh Desi Boora 300 grams',price:68,discountPrice:58},
                {id:1,image:Gur,title:'Shudh Desi gur 300 grams',price:68,discountPrice:58},
                {id:2,image:Khand,title:'Shudh Desi Khand 900 grams',price:500,discountPrice:350},
                {id:3,image:Mustard,title:'Cold Pressed Mustard Oil',price:90,discountPrice:65},
                {id:4,image:Shakkar,title:'Shudh Desi Shakkar 900 grams 1',price:500,discountPrice:350},
                {id:5,image:Shakkar,title:'Shudh Desi Shakkar 900 grams 2',price:500,discountPrice:350},
                {id:6,image:Shakkar,title:'Shudh Desi Shakkar 900 grams 3',price:500,discountPrice:350},
                {id:7,image:Shakkar,title:'Shudh Desi Shakkar 900 grams 4',price:500,discountPrice:350},
                
                
]





const services=[{id:0,logo:faIndianRupeeSign,description:'Money Back Gaurantee'},
                {id:1,logo:faTruckFast,description:'Free Shipping'},
                {id:2,logo:faHeadset,description:'24/7 Customer service'}
]





function Home() {
  const [scrollIndex, setScrollIndex] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [scrollTestimonial,setScrollTestimonial]=useState(0);
  const {blogs}=useContext(AppContext)
  const navigate=useNavigate()

  useEffect(() => {
        // Calculate container width for exactly 4 cards
        const calculateContainerWidth = () => {
            const cardWidth = 300; // Width of each card
            const margin = 30; // Total margin (left + right) around each card
            const visibleCards=4;
            setContainerWidth((cardWidth + margin) * visibleCards-margin); // 4 cards visible at a time
        };

        calculateContainerWidth();
        window.addEventListener('resize', calculateContainerWidth);
        return () => window.removeEventListener('resize', calculateContainerWidth);
    }, []);

    const handleScroll = (direction) => {
        const cardWidth = 300; // Width of each card
        const margin = 20; // Total margin (left + right) around each card
        const visibleCards = 4;
        const totalCards = products.length;
      
        const maxScroll = (totalCards - visibleCards) * (cardWidth + margin);

        let newIndex = scrollIndex + direction * (cardWidth + margin);
        if (newIndex < 0) newIndex = 0;
        if (newIndex > maxScroll) newIndex = maxScroll;

        setScrollIndex(newIndex);
    };
    
    const handleNavigate=()=>{
      navigate('/shop')
    }
    

    
    
  
  return (
    <div className='home'>
        <div>
        {/* <HeroCarousel/> */}
        
        <Hero/>
       
        </div>
        <div className='intro'>
           <div className='intro-content'>
            <h1>Delight Taste</h1>
            <h2>Indian A2 Cow Ghee</h2>
            <p>विदेशी नस्ल की गाय का घी हृदय रोग, मोटापा और शुगर का मुख्य कारण है हम भारतीय को इससे बचना होगा। खेतों में चरने वाली राजस्थानी गाय के दूध से निर्मित, 
              Good Cholesterol से भरपूर, खानें में स्वादिष्ट, आर्यधेनु देसी घी का सेवन करें - निरोग रहें। 
            </p>
            <button onClick={handleNavigate}>Shop Now</button>

           </div>
           <img src={Ghee} alt='Ghee'></img>
        </div>
        <div className='intro-left'>
          <img src={Khand500} alt='Khand'></img>
           <div className='intro-content-left'>
            
            <h1>Delight Taste</h1>
            <h2> Shudh Desi Khand</h2>
            <p>चीनी एक मीठा जहर है इसका त्याग करना ही होगा। मिठाईयाँ सबको पसन्द है लेकिन चीनी और नकली घी की मिठाईयाँ बीमारी की जड़ है इसलिए अपने घर में 
              आ﻿र्यधेनु देशी खाण्ड और देसी गाय के घी से मिठाईयाँ बनाकर खायें और खिलायें। 
            </p>
            <button onClick={handleNavigate}>Shop Now</button>

           </div>
           
        </div>
        
        <Products/>
        <div> <MakingProcess/></div>
        <div><Advantages/></div>
       
        
        
       <div><Reviews/></div>
        
        <BlogPagination blogs={blogs}/>
         <div className='contact'>
          <img src={family} alt='contact'/>
        </div>
         <Navbar isHomePage={true}/>
    </div>
  )
}

export default Home