// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* AdminPage.css

.admin-page {
    font-family: Arial, sans-serif;
    padding: 20px;
}

h1 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

thead {
    background-color: #f4f4f4;
}

th,
td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

th {
    background-color: #4CAF50;
    color: white;
    font-weight: bold;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

tr:hover {
    background-color: #ddd;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 5px;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination span {
    font-size: 16px;
    margin: 0 10px;
}

button{
        background-color: #4CAF50;
        border: none;
        color: white;
        padding: 10px 20px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        margin: 0 5px;
        cursor: pointer;
        border-radius: 5px;
        margin-bottom: 20px;
}

label{
    margin-right: 10px;
}


@media (max-width: 576px){
    .download{
        display: flex;
        flex-direction: column;
    }
} */`, "",{"version":3,"sources":["webpack://./src/Styles/Distributor/AdminPage.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAmGG","sourcesContent":["/* AdminPage.css\n\n.admin-page {\n    font-family: Arial, sans-serif;\n    padding: 20px;\n}\n\nh1 {\n    margin-bottom: 20px;\n    font-size: 24px;\n    color: #333;\n}\n\ntable {\n    width: 100%;\n    border-collapse: collapse;\n    margin-bottom: 20px;\n}\n\nthead {\n    background-color: #f4f4f4;\n}\n\nth,\ntd {\n    border: 1px solid #ddd;\n    padding: 12px;\n    text-align: left;\n}\n\nth {\n    background-color: #4CAF50;\n    color: white;\n    font-weight: bold;\n}\n\ntr:nth-child(even) {\n    background-color: #f2f2f2;\n}\n\ntr:hover {\n    background-color: #ddd;\n}\n\n.pagination {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.pagination button {\n    background-color: #4CAF50;\n    border: none;\n    color: white;\n    padding: 10px 20px;\n    text-align: center;\n    text-decoration: none;\n    display: inline-block;\n    font-size: 16px;\n    margin: 0 5px;\n    cursor: pointer;\n    border-radius: 5px;\n}\n\n.pagination button:disabled {\n    background-color: #ccc;\n    cursor: not-allowed;\n}\n\n.pagination span {\n    font-size: 16px;\n    margin: 0 10px;\n}\n\nbutton{\n        background-color: #4CAF50;\n        border: none;\n        color: white;\n        padding: 10px 20px;\n        text-align: center;\n        text-decoration: none;\n        display: inline-block;\n        font-size: 16px;\n        margin: 0 5px;\n        cursor: pointer;\n        border-radius: 5px;\n        margin-bottom: 20px;\n}\n\nlabel{\n    margin-right: 10px;\n}\n\n\n@media (max-width: 576px){\n    .download{\n        display: flex;\n        flex-direction: column;\n    }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
