

import React, { createContext, useState, useContext,useEffect } from 'react';
import axios from 'axios'

const OrderContext=createContext();

export const OrderProvider=({children})=>{
    const [bigShipToken,setBigShipToken]=useState("")
     const BACKEND_URL=process.env.REACT_APP_BACKEND_URL

    const fetchToken=async()=>{
        const response=await axios.post(`${BACKEND_URL}/bigship-login`);

        const data= response;
        
        const token=data.data.token;
        
        setBigShipToken(token)
        console.log(token)
    }

//    useEffect(() => {
//         // Initial fetch on mount
        

//         const intervalId = setInterval(() => {
//             fetchToken(); // Refresh the token every 11.5 hours
//         }, 0.01 * 60 * 60 * 1000); // 11.5 hours in milliseconds

//         return () => clearInterval(intervalId); // Cleanup on unmount
//     }, []);

    const value={bigShipToken,setBigShipToken}
   

    return (
        <OrderContext.Provider value={value}>
            {children}
        </OrderContext.Provider>
    )

}

export const useOrder=()=>useContext(OrderContext)