import React,{useContext,useEffect,useState} from 'react';
import '../../Styles/HeroSection.css'; // Adjust the path as necessary
import { useNavigate } from 'react-router-dom';


import { AppContext } from '../../AppContext';





const HeroSection = ({ imgSrc,mobileImgSrc, title, description,index }) => {
   const [currentImgSrc, setCurrentImgSrc] = useState(imgSrc);

  const {currentIndex}=useContext(AppContext)
  const navigate =useNavigate();
   useEffect(() => {
        const updateImage = () => {
            setCurrentImgSrc(window.innerWidth < 768 ? mobileImgSrc : imgSrc);
        };
        updateImage();

        window.addEventListener('resize', updateImage);
        return () => window.removeEventListener('resize', updateImage);
    }, [imgSrc, mobileImgSrc]);

 
  return (
    <div className='hero-content' >
      <div className='content' >
        <div className={`overlay ${index===currentIndex?'animate':''} ${index ===2 ? 'right' : 'left'}`}>
          <h1 className='header'>{title}</h1>
          <h5 className='description'>{description}</h5>
          <button onClick={()=>(navigate('/shop'))}>Shop</button>
        </div>
        
        <img src={currentImgSrc} alt='poster' className='poster' />
        
      </div>
      
      

   



      
      
    </div>
  );
};

export default HeroSection;
