import React from 'react'
import '../Styles/ReturnPolicy.css'
import Navbar from './Navbar'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faGreaterThan} 
from '@fortawesome/free-solid-svg-icons'

function ReturnPolicy() {

    const navigate=useNavigate()
  return (
    <>
    <div className='refund-policy-container'>
        <div className='page-info'>
            <h1>Refund/Return Policy</h1>
            <div className='page-info-navigation'>
                <div className='page-info-navigation-icons' onClick={()=>{navigate('/')}}><FontAwesomeIcon icon={faHouse}></FontAwesomeIcon></div>
                
               
            </div>
            </div>
        <h1>Refunds and Returns Policy</h1>
        <div className='refund-policy'>
            <h2>Returns Policy</h2>
            <p>At Aryadhenu Naturals, we are dedicated to your satisfaction and the quality of our products. 
                If you find the need to return an item, we’re here to assist you.</p>
        </div>
        <div className='refund-policy'>
            <h2>Eligibility for Returns</h2>
            <p>To be eligible for a return, your item must be unused and in the same condition you received it.
                 It must also be in the original packaging.</p>
            <p>You have 5 days from the date of purchase to initiate a return.</p>
        </div>
        <div className='refund-policy'>
            <h2>Initiating a Return</h2>
            <p>To start the return process, please contact our customer service team at customercare@aryadhenu.com/ +91 9467843826.
                 They will guide you through the steps and provide you with a Return Merchandise Authorization (RMA) number.</p>
        </div>
        <div className='refund-policy'>
            <h2>Packaging</h2>
            <p>When returning the item, ensure it is securely packaged to prevent damage during transit. 
                Please include a copy of your original receipt or proof of purchase along with the RMA number on the outside of the package.</p>
        </div>
        <div className='refund-policy'>
            <h2>Refund Process</h2>
            <p>Once we receive your returned item, our team will inspect it. We will then notify you of the approval or rejection of your refund. 
                If approved, your refund will be processed to your original method of payment.
                 The timing of the refund depends on your payment provider’s policies.</p>
        </div>
        <div className='refund-policy'>
            <h2>Shipping Costs</h2>
            <p>You are responsible for covering the shipping costs for returning your item.
                 Please note that shipping costs are non-refundable. </p>
        </div>
        <div className='refund-policy'>
            <h2>Contact Us</h2>
            <p>If you have any questions or concerns regarding the returns process, feel free to contact our customer service team at customercare@aryadhenu.com / +91 9467843826.
                 Your satisfaction is our priority, and we’re here to assist you every step of the way.</p>
        </div>
        
    </div>
    <Navbar isHomePage={false}/>
    </>
  )
}

export default ReturnPolicy