import React from 'react';
import '../../Styles/Distributor/ErrorPage.css'; // Import the CSS file for styling

function ErrorPage() {
  return (
    <div className="error-container">
      <div className="error-message">
        <h1>Submission Failed</h1>
        <p>Form not submitted. Please try again.</p>
      </div>
    </div>
  );
}

export default ErrorPage;
