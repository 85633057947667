import React,{useState} from 'react'
import '../Styles/Contact.css'
import contactBanner from '../Assets/contactus-banner.jpeg'
import Navbar from './Navbar'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faGreaterThan, faPhone, faEnvelope, faLocationArrow} 
from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import AlertPopup from './AlertPopup'

function Contact() {

    const navigate=useNavigate()
    const [name,setName]=useState('')
    const [subjects,setSubjects]=useState('')
    const [email,setEmail]=useState('')
    const [mobile,setMobile]=useState('')
    const [message,setMessage]=useState('')
    const [alertMessage, setAlertMessage] = useState('');
    const [errors,setErrors]=useState({})

    const [isValidation,setIsValidation]=useState(false);
     const BACKEND_URL=process.env.REACT_APP_BACKEND_URL

    const validateForm=()=>{
        const errors={}
        if(!name){
            errors.name='Please enter name'
        }
        if(!subjects){
            errors.subjects='Please select a subject'
        }
        if (!email) {
            errors.email = 'Please enter email';
        } else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
            errors.email = 'Please enter a valid email address';
        }
        if (!mobile) {
            errors.mobile = 'Please enter mobile';
        } else if (!/^(0?[6-9][0-9]{9})$/.test(mobile)) {
            errors.mobile = 'Phone number should be 10 digits, start with 6-9, and may have an optional leading zero';
        }
        if(!message){
            errors.message='Please enter message'
        }

        console.log(errors)
        if(Object.keys(errors).length === 0){
            return true;
        }
        else{
            setErrors(errors)
            return false;
        }

        

    }

    const handleSubmit=async()=>{
        const isValidFormSubmission=  validateForm();
        console.log(isValidFormSubmission,'isValidSubmission')

        const body={
            name:name,
            email:email,
            subject:subjects,
            mobileNo:mobile,
            message:message
        }

        if(isValidFormSubmission){
            try{
                const response=await axios.post(`${BACKEND_URL}/api/applications/message`,body)

                if(response.status===200){
                    setAlertMessage('Message sent successfully. Thanks for reaching us.')
                    setName('');
                    setSubjects('');
                    setEmail('');
                    setMobile('');
                    setMessage('');
                    setErrors({});

                }
                else{
                    setAlertMessage('Sorry!! Could not send your message.')
                }
            }

            catch(err){
                setAlertMessage('Failed to submit form.')
            }
        }
        else{
            setAlertMessage('Please fill all the form fields.')
        }
    }

    
  return (
    <div className='contact-container'> 
    
    <img src={contactBanner} alt='banner'></img>
    <AlertPopup message={alertMessage} onClose={() => setAlertMessage('')} />
    <div className='page-info'>
            <h1>Contact</h1>
            <div className='page-info-navigation'>
                <div className='page-info-navigation-icons' onClick={()=>{navigate('/')}}><FontAwesomeIcon icon={faHouse}></FontAwesomeIcon></div>
                
            </div>
    </div>
    <div className='contact-details-container'>
        <div className='contact-form'>
            <h5>Get in touch</h5>
            <h1>Write Us A Message</h1>
            <div className='input-fields'>
                
                 <div className='input'>
                <label htmlFor='name'>Your Name <span>*</span></label>
                <input
                 type='text'
                 required
                 value={name}
                 onChange={(e)=>{setName(e.target.value)}}
                 />
                 {errors.name?<div className='error-message'>{errors.name}</div>:''}
                 </div>
                 
                 <div className='input'>
                <label htmlFor='name'>Your Subjects <span>*</span></label>
                <select
                                required
                                value={subjects}
                                onChange={(e) => setSubjects(e.target.value)}
                            >
                                <option value=''>Select a subject</option>
                                <option value='Sales'>Sales</option>
                                <option value='Distributorship'>Distributorship</option>
                                <option value='Website issue'>Website Issue</option>
                                <option value='Order Related'>Order Related</option>
                                <option value='Payment or Refund'>Payment or Refund </option>
                                <option value='Account Related'>Account Related</option>
                                <option value='Others'>Others</option>
                            </select>
                    {errors.subjects?<div className='error-message'>{errors.subjects}</div>:''}
                 </div>
            </div>
            <div className='input-fields'>
                
                 <div className='input'>
                <label htmlFor='name'>Your Email<span>*</span></label>
                <input
                 type='text'
                 required
                 value={email}
                 onChange={(e)=>{setEmail(e.target.value)}}
                 />
                 {errors.email?<div className='error-message'>{errors.email}</div>:''}
                 </div>
                 <div className='input'>
                <label htmlFor='name'>Your Phone <span>*</span></label>
                <input
                 type='text'
                 required
                 value={mobile}
                 onChange={(e)=>{setMobile(e.target.value)}}
                 />
                 {errors.mobile?<div className='error-message'>{errors.mobile}</div>:''}
                 </div>
            </div>
            <div className='input-fields'>
                
                 <div className='input'>
                <label htmlFor='name'>Your Message<span>*</span></label>
                <textarea
                 type='text'
                 required
                 value={message}
                 onChange={(e)=>{setMessage(e.target.value)}}
                 />
                 {errors.message?<div className='error-message'>{errors.message}</div>:''}
                 </div>
                
            </div>
            <button onClick={handleSubmit}>SEND MESSAGE</button>
        </div>
        
        <div className='contact-information'>
            <div className='information-section'>
                <div className='icon'>
                    <FontAwesomeIcon icon={faPhone} style={{color:'#fff'}}/>
                </div>
                <h5>Call Us Now :</h5>
                <p>+91 94678 43826</p>
                <div className='border'></div>

            </div>
            <div className='information-section'>
                <div className='icon'>
                    <FontAwesomeIcon icon={faEnvelope} style={{color:'#fff'}}/>
                </div>
                <h5>Email :</h5>
                <p>customercare@aryadhenu.com</p>
                <div className='border'></div>

            </div>
            <div className='information-section'>
                <div className='icon'>
                    <FontAwesomeIcon icon={faLocationArrow} style={{color:'#fff'}}/>
                </div>
                <h5>Our Address :</h5>
                <p>Village Balawas, PO Nalwa</p>
                <p>Hisar, Haryana 125037, India</p>
                <div className='border'></div>

            </div>

        </div>
    </div>

     <Navbar isHomePage={false} />
    </div>
  )
}

export default Contact