import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import '../Styles/AdminDashboard.css';
import Navbar from './Navbar';
import AddProducts from './AddProducts';
import UpdateProduct from './UpdateProductForm';
import BlogForm from './Blogs/BlogForm';
import UpdateSlideForm from './UpdateSlideForm';
import CreateBlog from './Blogs/CreateBlog';
import AlertPopup from './AlertPopup';
import SaleForm from './SaleForm';
import ManageSales from './ManageSales';

const isTokenValid = () => {
    const token = localStorage.getItem('token');
    const expiry = localStorage.getItem('tokenExpiry');
    return token && new Date().getTime() < expiry;
};

function AdminDashboard() {
    const [orderDetails, setOrderDetails] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sortDirection, setSortDirection] = useState('desc');
    const navigate = useNavigate();
    const [activeSection, setActiveSection] = useState('orderDetails');
    const [searchTerm, setSearchTerm] = useState('');
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const [cancellationRequests, setCancellationRequests] = useState([]);
    const [showCancellationsOnly, setShowCancellationsOnly] = useState(false);
    const [slides, setSlides] = useState([]);
    const [selectedSlide, setSelectedSlide] = useState(null);
    const [alertMessage,setAlertMessage]=useState('')
    const [jobs,setJobs]=useState([]);
    const [selectedJob, setSelectedJob] = useState('');
    

     const [job, setJob] = useState({
        title: '',
        company: '',
        location: '',
        description: ''
     });

    useEffect(() => {
        const email = localStorage.getItem('email');
        
        if (email !== 'aryadhenu@gmail.com') {
            setAlertMessage('Access denied. Only admin can view this page.');
            navigate('/login');
            return;
        }

        if (!isTokenValid()) {
            setAlertMessage('Session expired. Please log in again.');
            navigate('/login');
            return;
        }

        const fetchAllOrderDetails = async () => {
            setLoading(true);
            try {
                const response = await axios.post(`${BACKEND_URL}/get-all-orders`);
                const sortedOrders = response.data.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));
                setOrderDetails(sortedOrders);
                setFilteredOrders(sortedOrders); // Initially, all orders are displayed
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch order details:', error);
                setError('Failed to fetch order details.');
                setLoading(false);
            }
        };

        fetchAllOrderDetails();

        const tokenExpiryHandler = setInterval(() => {
            if (!isTokenValid()) {
                clearInterval(tokenExpiryHandler);
                setAlertMessage('Session expired. Redirecting to login.');
                navigate('/login');
            }
        }, 60000);

        return () => clearInterval(tokenExpiryHandler);
    }, [navigate]);

    useEffect(() => {
        const fetchCancellationRequests = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/get-cancellation-request`);
                setCancellationRequests(response.data.map((request) => request));
            } catch (error) {
                console.error('Failed to fetch cancellation requests:', error);
            }
        };
        fetchCancellationRequests();
    }, []);

    const getCancellationStatus = (orderId) => {
        const request = cancellationRequests.find(
            (request) => request.orderId === orderId
        );
        return request ? 'Cancellation Requested' : 'No Request';
    };

    const formatDateToIndian = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-IN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
    };

    // Handle Search Filter
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    // Apply filter based on search term
    // useEffect(() => {
    //     const filtered = orderDetails.filter((order) =>
    //         order.orderId.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //         order.emailAddress.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //         order.orderStatus.toLowerCase().includes(searchTerm.toLowerCase())
          
    //     );
    //     setFilteredOrders(filtered);
    // }, [searchTerm, orderDetails]);

     useEffect(() => {
        const filtered = orderDetails.filter((order) => {
            const matchesSearch = order.orderId.toLowerCase().includes(searchTerm.toLowerCase()) ||
                order.emailAddress.toLowerCase().includes(searchTerm.toLowerCase()) ||
                order.orderStatus.toLowerCase().includes(searchTerm.toLowerCase());

            const matchesCancellation = !showCancellationsOnly || getCancellationStatus(order.orderId) === 'Cancellation Requested';

            return matchesSearch && matchesCancellation;
        });
        setFilteredOrders(filtered);
    }, [searchTerm, orderDetails, showCancellationsOnly]);

    // Manual Sort (Sort by order date)
    // const sortByDate = () => {
    //     const sortedOrders = [...filteredOrders].sort((a, b) => new Date(a.orderDate) - new Date(b.orderDate));
    //     setFilteredOrders(sortedOrders);
    // };


     const toggleSortByDate = () => {
        const sorted = [...filteredOrders].sort((a, b) => {
            return sortDirection === 'desc'
                ? new Date(a.orderDate) - new Date(b.orderDate)
                : new Date(b.orderDate) - new Date(a.orderDate);
        });
        setFilteredOrders(sorted);
        setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc'); // Toggle the sorting direction
    };

    // Pagination logic
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const totalPages = Math.ceil(filteredOrders.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const currentOrders = filteredOrders.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );


    useEffect(() => {
    const fetchSlides = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/slides/get-slides`);
        const slidesArray=Object.values(response.data)
        setSlides(slidesArray);
      } catch (error) {
        console.error("Error fetching slides:", error);
      }
    };
    fetchSlides();
    }, []);

  const handleSlideSelect = (slide) => {
    setSelectedSlide(slide);
  };

  const handleSlideUpdate = (updatedSlide) => {
    setSlides((prevSlides) =>
      prevSlides.map((slide) =>
        slide._id === updatedSlide._id ? updatedSlide : slide
      )
    );
    setSelectedSlide(null); // Clear the selected slide after update
  };
   
  const handleChange = (e) => setJob({ ...job, [e.target.name]: e.target.value });

  const handleSubmit = async (e) => {
    e.preventDefault();
     const jobData = {
      ...job,
      description: job.description.split('\n').filter(paragraph => paragraph.trim() !== "")
    };
    console.log(jobData,'job data')
    try {
      await axios.post(`${BACKEND_URL}/post-job`, jobData);
      setAlertMessage('Job posted successfully!');
    } catch (error) {
      console.error('Error posting job:', error);
    }
  }; 

   useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get('${BACKEND_URL}/get-jobs');
        setJobs(response.data);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };
    fetchJobs();
  }, []);

  const handleDelete = async () => {
    if (!selectedJob) {
      setAlertMessage('Please select a job to delete.');
      return;
    }
    try {
      await axios.delete(`${BACKEND_URL}/delete-job/${selectedJob}`);
      setAlertMessage('Job deleted successfully');
      setJobs(jobs.filter((job) => job._id !== selectedJob));
      setSelectedJob('');
    } catch (error) {
      console.error('Error deleting job:', error);
      setAlertMessage('Failed to delete job');
    }
  };


  
    

    return (
        <>
        { loading?<div className='loader'></div>:(
        <div className="admin-dashboard">
            
            <aside className="sidebar">
                <button onClick={() => setActiveSection('orderDetails')}>Orders</button>
                <button onClick={() => setActiveSection('addProduct')}>Add Product</button>
                <button onClick={() => setActiveSection('updateProducts')}>Update Products</button>
                <button onClick={() => setActiveSection('addBlogs')}>Add Blog</button>
                <button onClick={()=>setActiveSection('manageHeroSlides')}>Manage Hero Slides</button>
                <button onClick={()=>setActiveSection('addJob')}>Add Job</button>
                <button onClick={()=>setActiveSection('deleteJob')}>Delete Job</button>
                <button onClick={()=>setActiveSection('createSale')}>Create Sale</button>
                <button onClick={()=>setActiveSection('manageSale')}>Manage Sale</button>
            </aside>
            <AlertPopup message={alertMessage} onClose={() => setAlertMessage('')} />
            <main className="content">
                {activeSection === 'orderDetails' && (
                    <div className="table-container">
                        <h2>Orders</h2>
                        <div className='dashboard-filter-container'>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearch}
                            placeholder="Search by Order ID, Email or Status"
                            className="search-input"
                        />
                        <button onClick={toggleSortByDate}>
                        Sort by Date ({sortDirection === 'desc' ? 'Newest First' : 'Oldest First'})
                        </button>
                        <label>
                                <input
                                    type="checkbox"
                                    checked={showCancellationsOnly}
                                    onChange={(e) => setShowCancellationsOnly(e.target.checked)}
                                />
                                Show Cancellation Requests 
                            </label>
                        </div>
                        <table className="order-table">
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Status</th>
                                    <th>Email</th>
                                    <th>Cancellation</th>
                                    <th>Payment Status</th>
                                    <th>Shipping Address</th>
                                    <th>Ordered Date</th>
                                    <th>Transaction ID</th>
                                    <th>Tracking Number</th>
                                    <th>Total Amount</th>
                                    <th>Ordered Products</th>
                                    <th>Source</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentOrders.map((order) => (
                                    <tr key={order.orderId}>
                                        <td>{order.orderId}</td>
                                        <td>{order.orderStatus}</td>
                                        <td>{order.emailAddress}</td>
                                        <td>{getCancellationStatus(order.orderId)}</td>
                                        <td>{order.paymentStatus}</td>
                                        <td>{order.shippingAddress}</td>
                                        <td>{formatDateToIndian(order.orderDate)}</td>
                                        <td>{order.transactionId}</td>
                                        <td>{order.trackingNumber}</td>
                                        <td>{order.totalAmount}</td>
                                        <td>
                                            <ul>
                                                {order.products?.map((product) => (
                                                    <li key={product.id}>{product.productName} X {product.quantity}</li>
                                                ))}
                                            </ul>
                                        </td>
                                        {order.utmDetails?
                                        <td>{order.utmDetails.utm_source || order.utmDetails.referrer}</td>
                                        :''}
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className="dashboard-pagination">
                            <button
                                onClick={() => handlePageChange(currentPage - 1)}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            <span>
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                onClick={() => handlePageChange(currentPage + 1)}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                )}
                {activeSection === 'addProduct' && <AddProducts />}
                {activeSection === 'updateProducts' && <UpdateProduct />}
                {activeSection === 'addBlogs' && <CreateBlog/>}
                {activeSection==='manageHeroSlides' && 
                <div className='manage-slides-container'>
                  <h2>Manage Slides</h2>
                  <div className='manage-slides-content'>
                   <ul>
                     {slides.map((slide) => (
                     <li key={slide._id}>
                     <h3>{slide.title}</h3>
                    <img src={slide.imgSrc} ></img>
                   <button onClick={() => handleSlideSelect(slide)}>Edit</button>
                   </li>
                   ))}
                 </ul>

                {selectedSlide && (
                <UpdateSlideForm slide={selectedSlide} onUpdate={handleSlideUpdate} />
                )}
                </div>
                </div>
                }
                {activeSection==='addJob' && 
                <div className='add-job'>
                 <form onSubmit={handleSubmit} >
                    <h2>Add a Job Post</h2>
                    <input type="text" name="title" placeholder="Job Title" value={job.title} onChange={handleChange} required />
                    <input type="text" name="company" placeholder="Company" value={job.company} onChange={handleChange} required />
                    <input type="text" name="location" placeholder="Location" value={job.location} onChange={handleChange} required />
                    <textarea name="description" placeholder="Description (Separate paragraphs with line breaks)" value={job.description} onChange={handleChange} required />
                    <button type="submit">Post Job</button>
                </form> 
                </div>
                }

                {activeSection==='deleteJob' &&
                  <div className='delete-job'>
                    <h2>Delete a Job</h2>
                    <select value={selectedJob} onChange={(e) => setSelectedJob(e.target.value)}>
                    <option value="">Select a job to delete</option>
                    {jobs.map((job) => (
                    <option key={job._id} value={job._id}>
                    {job.title}
                    </option>
                ))}
                </select>
                <button onClick={handleDelete} disabled={!selectedJob}>Delete Job</button>
                </div>
                }
                {activeSection==='createSale' && <SaleForm/>}
                {activeSection==='manageSale' && <ManageSales/>}
            </main>
            
        </div>
           )}
        <Navbar isHomePage={false} />
        </>
    );
}

export default AdminDashboard;
