import React from 'react';
import { useNavigate} from 'react-router-dom';
import '../../Styles/blog-items.css'

const BlogItems = ({blog}) => {
    const navigate = useNavigate();

    const handleReadMore = () => {
        navigate(`/blogs/${blog._id}`);  // Adjust based on your routing setup
    };

    return (
        <div className="blog-items-container">
            
            <img src={blog.image} alt={blog.title} />
            <div className='blog-items'>
            <h3>{blog.title}</h3>
            <p>{blog.summary}</p> {/* Display the summary here */}
            <button onClick={handleReadMore}>Read More</button>
            </div>
        </div>
    );
};

export default BlogItems;
