import React, { useState } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';
import Navbar from './Navbar';

const BACKEND_URL=process.env.REACT_APP_BACKEND_URL

const ResetPasswordForm = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword,setConfirmPassword]=useState('');
    const [message, setMessage] = useState('');
    const { token } = useParams();
    const [errors,setErrors]=useState({})
    const navigate=useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors={}
        
        if (!newPassword.trim()) {
          errors.newPassword = 'Password is required.';
        } else if (newPassword.length < 8) {
          errors.newPassword = 'Password must be at least 8 characters long.';
        }
        else if(newPassword!=confirmPassword){
          errors.newPassword='Passwords not matching'
        }
        if (Object.keys(errors).length > 0) {
          setErrors(errors);
          return;
         }
        try {
            const response = await axios.post(`${BACKEND_URL}/reset-password`, { token, newPassword });
            setMessage(response.data.message);
            setNewPassword('')
            setConfirmPassword('')
            navigate('/Login')
        } catch (error) {
            setMessage('Error resetting password');
        }
    };

    return (
        <>
        <div className='request-password-reset'>
            <div className='password-reset-card'>
            <h2>Reset Password</h2>
            <form onSubmit={handleSubmit}>
                <input type="password" placeholder="New Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                <input type="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)} required/>
                {errors.newPassword && <div className='error-message'>{errors.newPassword}</div>}
                <button type="submit" className='request-reset-button'>Reset Password</button>
            </form>
            <p className='error-message'>{message}</p>
            </div>
        </div>
        <Navbar isHomePage={false}/>
        </>
    );
};

export default ResetPasswordForm;
