import React, { createContext, useState, useContext,useEffect } from 'react';


const CartContext = createContext();


export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
    const [subtotal,setSubTotal]=useState(0)
    
    const [deadWeight,setDeadWeight]=useState(0);
    const [shippingCost,setShippingCost]=useState(0)
    const [pincode,setPincode]=useState('125037')

  const storedCart = localStorage.getItem('cart');
  useEffect(() => {
    // Load cart from localStorage when the app initializes
    if (storedCart) {
      setCart(JSON.parse(storedCart));
     console.log('Cart loaded from localStorage:', JSON.parse(storedCart));
    } else {
      console.log('No cart found in localStorage, initializing empty cart.');
    }
    
  }, []);

  useEffect(() => {
    // Save cart to localStorage whenever it changes
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const addToCart = (product, quant) => {
    setCart(prevCart => {
      const existingProductIndex = prevCart.findIndex(item => item.product._id === product._id);
      if (existingProductIndex >= 0) {
        
        const updatedCart = [...prevCart];
        updatedCart[existingProductIndex].quant += quant;
         
        return updatedCart;
      } else {
        
        return [...prevCart, { product, quant }];
       
      }
    });
    console.log(cart,'cart')
  };

  const removeFromCart = (productId) => {
    setCart(prevCart => prevCart.filter(item => item.product._id !== productId));
  };

  const getCartItemCount = () => {
    console.log(cart,'cart in getItemCount')
    return cart.reduce((total, item) => total + item.quant, 0);
    // return cart.length;
    
  };
 

  const incrementQuantity = (productId) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.product._id === productId
          ? { ...item, quant: item.quant + 1 }
          : item
      )
    );
  };

  // const decrementQuantity = (productId) => {
  //   setCart((prevCart) =>
  //     prevCart.map((item) =>
  //       item.product._id === productId
  //         ? { ...item, quant: Math.max(1, item.quant - 1) } // Prevent negative quantities
  //         : item
  //     )
  //   );
  // };
  const decrementQuantity = (productId) => {
  setCart((prevCart) =>
    prevCart.reduce((acc, item) => {
      if (item.product._id === productId) {
        // If quantity is greater than 1, decrement it
        if (item.quant > 1) {
          acc.push({ ...item, quant: item.quant - 1 });
        }
        // If quantity is 1, do not add it to the accumulator, effectively removing it
      } else {
        acc.push(item); // Keep other items unchanged
      }
      return acc;
    }, [])
  );
};


  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, getCartItemCount,incrementQuantity,decrementQuantity ,subtotal,
      setSubTotal,deadWeight,setDeadWeight,shippingCost,setShippingCost,pincode,setPincode
    }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
