// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-carousel {
    position: relative;
    max-width: 100%;
    margin: auto;
}

.carousal-container {
    width:400px;
    height:400px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding:20px;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.carousel-image {
    width: 100%;
    height: auto;
}

.carousel-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.carousel-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
}

.carousel-button:hover {
    background-color: #0056b3;
}

.carousel-indicators {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.indicator {
    height: 10px;
    width: 10px;
    margin: 0 5px;
    background-color: #ccc;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
}

.indicator.active {
    background-color: #007bff;
}

@media screen and (max-width:768px){
    .carousal-container{
        width:90%;
        height:300px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Styles/ProductCarousel.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI;QACI,SAAS;QACT,YAAY;IAChB;AACJ","sourcesContent":[".product-carousel {\n    position: relative;\n    max-width: 100%;\n    margin: auto;\n}\n\n.carousal-container {\n    width:400px;\n    height:400px;\n    overflow: hidden;\n    display: flex;\n    justify-content: center;\n    padding:20px;\n    border: 1px solid #ccc;\n    border-radius: 8px;\n}\n\n.carousel-image {\n    width: 100%;\n    height: auto;\n}\n\n.carousel-buttons {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 10px;\n}\n\n.carousel-button {\n    background-color: #007bff;\n    color: white;\n    border: none;\n    padding: 10px 15px;\n    border-radius: 4px;\n    cursor: pointer;\n}\n\n.carousel-button:hover {\n    background-color: #0056b3;\n}\n\n.carousel-indicators {\n    display: flex;\n    justify-content: center;\n    margin-top: 10px;\n}\n\n.indicator {\n    height: 10px;\n    width: 10px;\n    margin: 0 5px;\n    background-color: #ccc;\n    border-radius: 50%;\n    display: inline-block;\n    cursor: pointer;\n}\n\n.indicator.active {\n    background-color: #007bff;\n}\n\n@media screen and (max-width:768px){\n    .carousal-container{\n        width:90%;\n        height:300px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
