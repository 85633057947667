// import React, { useState, useEffect } from 'react';

// function AddAddress({ onClose, onSave, initialData }) {
//      const [firstName, setFirstName] = useState(initialData?.firstName || '');
//     const [lastName, setLastName] = useState(initialData?.lastName || '');
//     const [streetAddress, setStreetAddress] = useState(initialData?.streetAddress || '');
//     const [townCity, setTownCity] = useState(initialData?.townCity || '');
//     const [state, setState] = useState(initialData?.state || '');
//     const [pincode, setPincode] = useState(initialData?.pincode || '');
//     const [phone, setPhone] = useState(initialData?.phone || '');
//     const [errors, setErrors] = useState({});

//     // Set initial data when editing an existing address
//     useEffect(() => {
//         if (initialData) {
//             setFirstName(initialData.firstName || '');
//             setLastName(initialData.lastName || '');
//             setStreetAddress(initialData.streetAddress || '');
//             setTownCity(initialData.townCity || '');
//             setState(initialData.state || '');
//             setPincode(initialData.pincode || '');
//             setPhone(initialData.phone || '');
//         }
//     }, [initialData]);

//     const handleSave = (e) => {
//         e.preventDefault();
        
//         // Basic validation (example)
//         const newErrors = {};
//         if (!firstName) newErrors.firstName = 'First name is required';
//         if (!lastName) newErrors.lastName = 'Last name is required';
//         if (!streetAddress) newErrors.streetAddress = 'Street address is required';
//         if (!townCity) newErrors.townCity = 'Town/City is required';
//         if (!state) newErrors.state = 'State is required';
//         if (!pincode) newErrors.pincode = 'Pincode is required';
//         if (!phone) newErrors.phone = 'Phone number is required';

//         setErrors(newErrors);

//         if (Object.keys(newErrors).length === 0) {
//             // Pass data to onSave
//             const addressData = {
//                 firstName,
//                 lastName,
//                 streetAddress,
//                 townCity,
//                 state,
//                 pincode,
//                 phone,
//             };
//             onSave(addressData);
//         }
//     };

//     return (
//         <div className="add-address">
//             <h2>{initialData ? 'Edit Address' : 'Add New Address'}</h2>
//             <form onSubmit={handleSave}>
//                 <div className="form-group">
//                     <label>First Name *</label>
//                     <input 
//                         type="text" 
//                         value={firstName} 
//                         onChange={(e) => setFirstName(e.target.value)} 
//                     />
//                     {errors.firstName && <div className="error-message">{errors.firstName}</div>}
//                 </div>

//                 <div className="form-group">
//                     <label>Last Name *</label>
//                     <input 
//                         type="text" 
//                         value={lastName} 
//                         onChange={(e) => setLastName(e.target.value)} 
//                     />
//                     {errors.lastName && <div className="error-message">{errors.lastName}</div>}
//                 </div>

//                 <div className="form-group">
//                     <label>Street Address *</label>
//                     <input 
//                         type="text" 
//                         value={streetAddress} 
//                         onChange={(e) => setStreetAddress(e.target.value)} 
//                     />
//                     {errors.streetAddress && <div className="error-message">{errors.streetAddress}</div>}
//                 </div>

//                 <div className="form-group">
//                     <label>Town/City *</label>
//                     <input 
//                         type="text" 
//                         value={townCity} 
//                         onChange={(e) => setTownCity(e.target.value)} 
//                     />
//                     {errors.townCity && <div className="error-message">{errors.townCity}</div>}
//                 </div>

//                 <div className="form-group">
//                     <label>State *</label>
//                     <input 
//                         type="text" 
//                         value={state} 
//                         onChange={(e) => setState(e.target.value)} 
//                     />
//                     {errors.state && <div className="error-message">{errors.state}</div>}
//                 </div>

//                 <div className="form-group">
//                     <label>Pincode *</label>
//                     <input 
//                         type="text" 
//                         value={pincode} 
//                         onChange={(e) => setPincode(e.target.value)} 
//                     />
//                     {errors.pincode && <div className="error-message">{errors.pincode}</div>}
//                 </div>

//                 <div className="form-group">
//                     <label>Phone *</label>
//                     <input 
//                         type="text" 
//                         value={phone} 
//                         onChange={(e) => setPhone(e.target.value)} 
//                     />
//                     {errors.phone && <div className="error-message">{errors.phone}</div>}
//                 </div>

//                 <div className="form-actions">
//                     <button type="button" onClick={onClose}>Cancel</button>
//                     <button type="submit">Save Address</button>
//                 </div>
//             </form>
//         </div>
//     );
// }

// export default AddAddress;


import React, { useState, useEffect } from 'react';

function AddAddress({ onClose, onSave, initialData }) {
    const [firstName, setFirstName] = useState(initialData?.firstName || '');
    const [lastName, setLastName] = useState(initialData?.lastName || '');
    const [streetAddress, setStreetAddress] = useState(initialData?.streetAddress || '');
    const [townCity, setTownCity] = useState(initialData?.townCity || '');
    const [state, setState] = useState(initialData?.state || '');
    const [pincode, setPincode] = useState(initialData?.pincode || '');
    const [phone, setPhone] = useState(initialData?.phone || '');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (initialData) {
            setFirstName(initialData.firstName || '');
            setLastName(initialData.lastName || '');
            setStreetAddress(initialData.streetAddress || '');
            setTownCity(initialData.townCity || '');
            setState(initialData.state || '');
            setPincode(initialData.pincode || '');
            setPhone(initialData.phone || '');
        }
    }, [initialData]);

    const validateForm = () => {
        const newErrors = {};

        // Validate fields using regex patterns as in the earlier validation function
        if (!/^[A-Za-z .]{3,25}$/.test(firstName)) {
            newErrors.firstName = 'First name length should be between 3 and 25 characters. Only alphabets, dots(.), spaces are allowed.';
        }
        if (!/^[A-Za-z .]{3,25}$/.test(lastName)) {
            newErrors.lastName = 'Last name length should be between 3 and 25 characters. Only alphabets, dots(.), spaces are allowed.';
        }
        if (!/^[A-Za-z0-9'.,\-\/ ]{10,50}$/.test(streetAddress)) {
            newErrors.streetAddress = 'Street Address must be between 10 and 50 characters. Only Alphanumeric, spaces and some Special characters like \'. , - / are allowed';
        }
        if (!/^[A-Za-z0-9'.,\-\/ ]{0,50}$/.test(townCity)) {
            newErrors.townCity = 'Town/City must be between 10 and 50 characters. Only Alphanumeric, spaces and some Special characters like \'. , - / are allowed';
        }
        if (!/^[A-Za-z0-9'.,\-\/ ]{0,50}$/.test(state)) {
            newErrors.state = 'State must be between 10 and 50 characters. Only Alphanumeric, spaces and some Special characters like \'. , - / are allowed';
        }
        if (!/^[0-9]{6}$/.test(pincode)) {
            newErrors.pincode = 'Pincode should be a valid 6 digit pincode.';
        }
        if (!/^[06789][0-9]{9,11}$/.test(phone)) {
            newErrors.phone = 'Phone number should be 10-12 characters long and a valid phone number.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors, false if errors exist
    };

    const handleSave = (e) => {
        e.preventDefault();

        // Perform validation before saving
        if (validateForm()) {
            const addressData = {
                firstName,
                lastName,
                streetAddress,
                townCity,
                state,
                pincode,
                phone,
            };
            console.log("Address data to save:", addressData);  // Debugging log
            onSave(addressData);  // Pass data to onSave function (provided by parent component)
        }
    };

    return (
        <div className="add-address">
            <h2>{initialData ? 'Edit Address' : 'Add New Address'}</h2>
            <form onSubmit={handleSave}>
                <div className="form-group">
                    <label>First Name *</label>
                    <input 
                        type="text" 
                        value={firstName} 
                        onChange={(e) => setFirstName(e.target.value)} 
                    />
                    {errors.firstName && <div className="error-message">{errors.firstName}</div>}
                </div>

                <div className="form-group">
                    <label>Last Name *</label>
                    <input 
                        type="text" 
                        value={lastName} 
                        onChange={(e) => setLastName(e.target.value)} 
                    />
                    {errors.lastName && <div className="error-message">{errors.lastName}</div>}
                </div>

                <div className="form-group">
                    <label>Street Address *</label>
                    <input 
                        type="text" 
                        value={streetAddress} 
                        onChange={(e) => setStreetAddress(e.target.value)} 
                    />
                    {errors.streetAddress && <div className="error-message">{errors.streetAddress}</div>}
                </div>

                <div className="form-group">
                    <label>Town/City *</label>
                    <input 
                        type="text" 
                        value={townCity} 
                        onChange={(e) => setTownCity(e.target.value)} 
                    />
                    {errors.townCity && <div className="error-message">{errors.townCity}</div>}
                </div>

                <div className="form-group">
                    <label>State *</label>
                    <input 
                        type="text" 
                        value={state} 
                        onChange={(e) => setState(e.target.value)} 
                    />
                    {errors.state && <div className="error-message">{errors.state}</div>}
                </div>

                <div className="form-group">
                    <label>Pincode *</label>
                    <input 
                        type="text" 
                        value={pincode} 
                        onChange={(e) => setPincode(e.target.value)} 
                    />
                    {errors.pincode && <div className="error-message">{errors.pincode}</div>}
                </div>

                <div className="form-group">
                    <label>Phone *</label>
                    <input 
                        type="text" 
                        value={phone} 
                        onChange={(e) => setPhone(e.target.value)} 
                    />
                    {errors.phone && <div className="error-message">{errors.phone}</div>}
                </div>

                <div className="form-actions">
                    <button type="button" onClick={onClose}>Cancel</button>
                    <button type="submit">Save Address</button>
                </div>
            </form>
        </div>
    );
}

export default AddAddress;


