import React, { useState, useEffect,useContext } from 'react';
import HeroSection from './HeroSection';
import '../../Styles/HeroCarousal.css';
import { AppContext } from '../../AppContext';





const HeroCarousel = () => {
  const {slides,currentIndex,setCurrentIndex,loading}=useContext(AppContext)
//   const [currentIndex, setCurrentIndex] = useState(1);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    
  };

  const prevSlide = () => {
    //setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
    setCurrentIndex((prevIndex) => {
      if (prevIndex === 0) {
        return slides.length - 1; // Wrap to the last slide if at the first slide
      } else {
        return prevIndex - 1;
      }
    });
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 3000); // Change slide every 3 seconds
    return () => clearInterval(interval); // Cleanup on unmount
  }, [currentIndex, slides.length]);
 //
  return (
    <>
    {loading?<div className='loader'></div>:(
    <div className='carousel-container'>
      <div className='carousel-slides' style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {slides.map((slide, index) => (
          
          <div
            key={slide.id}
            className={`hero-section ${index === currentIndex ? 'active' : ''}`}
             
          >
            <HeroSection
              imgSrc={slide.imgSrc}
              mobileImgSrc={slide.mobileImgSrc}
              title={slide.title}
              description={slide.description}
              index={index}
              // backgroundColor={  slide.backgroundColor }
            />
           
          </div>
          
          
          
        ))}
         
        
      </div>
      
      <button className='carousel-control prev' onClick={prevSlide}><p>&lt;</p></button>
      <button className='carousel-control next' onClick={nextSlide}><p>&gt;</p></button>
    </div>
    )}</>
  );
};

export default HeroCarousel;
