import React  ,{useState} from 'react'
import FormPage8 from './FromPage8'
import FormPage2 from './FormPage2';

import '../../Styles/Distributor/Form.css'
import Landing_image from '../../Assets/Landing_image.jpg'

function Form4() {

  const [currentPage, setCurrentPage] = useState(1);
  const [formData, setFormData] = useState({});

  const nextPage = () => setCurrentPage(currentPage + 1);
  const previousPage = () => setCurrentPage(1);

  

  return (
    <div className='distributor-form'>
        <img src={Landing_image} alt='poster'></img>
        {currentPage === 1 && (
        <FormPage8
          onNext={nextPage}
          formData={formData}
          setFormData={setFormData}
          setCurrentPage={setCurrentPage}
        />
      )}
      {currentPage === 2 && (
        <FormPage2
          onNext={nextPage}
          onPrevious={previousPage}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      
      
      
    </div>
    
)
}

export default Form4