// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.share-component{
    display: flex;
    flex-direction: row;
    width:250px;
    height:100%;
    margin-top: 15px;
    justify-content: center;
    align-items: center;
}

.share-component-icons{
    margin-right: 0px;
    margin-left: auto;
    

}`, "",{"version":3,"sources":["webpack://./src/Styles/ShareComponent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,WAAW;IACX,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;;;AAGrB","sourcesContent":[".share-component{\n    display: flex;\n    flex-direction: row;\n    width:250px;\n    height:100%;\n    margin-top: 15px;\n    justify-content: center;\n    align-items: center;\n}\n\n.share-component-icons{\n    margin-right: 0px;\n    margin-left: auto;\n    \n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
