import React from 'react';
import '../Styles/cartPopup.css'
import { useCart } from '../CartContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faIndianRupeeSign, faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const CartPopup = ({ product,onCheckout,onClose,quantity}) => {

    const navigate=useNavigate()

    const naviagteToCart=()=>{
     navigate('/cart')
    }


    const {cart}=useCart()

    console.log(product,'product in the popup ')
    console.log(quantity,'quantity in the popup')
    return (
        <div className="popup">
            <div className="popup-content">
                <div className='popup-header'>
                <p>Product successfully added to your shopping cart</p>
                <span className="close" onClick={onClose}>&times;</span>
                </div>
                
               
                <div className="cart-items">
                    
                    <img src={product.imageUrl} alt='product'></img>
                    <div className='cart-product-details'>
                     <h5 className='cart-length'>There are {cart.length} items in your cart.</h5>
                    <p>{product.title}</p>
                    <div className='price-container'>
                                <p style={{ color: '#e42727', fontWeight: 700,fontSize:'20px' }}>
                                    <FontAwesomeIcon icon={faIndianRupeeSign} />{product.discountPrice}
                                </p>
                                <p style={{ textDecoration: 'line-through',color:'#444' }}>
                                    <FontAwesomeIcon icon={faIndianRupeeSign} />{product.quotePrice}
                                </p>
                            </div>
                    <p>Quantity: {quantity}</p>
                    <div className='cart-buttons'>
                            <button onClick={naviagteToCart} className='cart-button'>
                                <FontAwesomeIcon icon={faCartShopping} style={{ color: '#ffffff' }} /> View in Cart
                            </button>

                           
                            <button className='buy-button' onClick={onCheckout}>Check Out</button>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </div>
    );
};

export default CartPopup;
