import React, { useState ,useEffect, useContext} from 'react'
import '../Styles/Checkout.css'
import Navbar from './Navbar'
import { useCart } from '../CartContext'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faGreaterThan} 
from '@fortawesome/free-solid-svg-icons'
import Payment from './Payment'
import '../Styles/LoggedInCheckout.css'
import AlertPopup from './AlertPopup'
import { AppContext } from '../AppContext'

const isTokenValid = () => {
    const token = localStorage.getItem('token');
    const expiry = localStorage.getItem('tokenExpiry');
    return token && new Date().getTime() < expiry;
};

function LoggedInCheckout() {
    
    const [signUpcheck,setSignUpcheck]=useState(false)
    const [isCouponCodeVisible,setIsCouponCodeVisible]=useState(false)
    const [isShippingAddressDifferent,setIsShippingAddressDifferent]=useState(false)
     const email = localStorage.getItem('email');
    const navigate=useNavigate()
    const {cart,deadWeight,pincode,setPincode,subtotal,setSubTotal,shippingCost,setShippingCost,setDeadWeight,removeFromCart}=useCart();
    const [loading, setLoading] = useState(false);
    const [systemOrderId,setSystemOrderId]=useState('')
    const [courierId,setCourierId]=useState('')
    const [couponCode,setCouponCode]=useState('')
    const [discountedSubtotal,setDiscountedSubtotal]=useState('')
    const [discount,setDiscount]=useState(null)
    const [errors,setErrors]=useState({})
     const BACKEND_URL=process.env.REACT_APP_BACKEND_URL

    const [addresses,setAddresses]=useState([])
    const [billingAddress,setBillingAddress]=useState({})
    const [shippingAddress,setShippingAddress]=useState({})
    const [alertMessage,setAlertMessage]=useState('')
    const {sales}=useContext(AppContext)

    const coupons=[
        {id:'66beebffa473757ab3382782',
         value:50,
         code:'SAVEON5'
        }
    ]

    

    const [paymentDetails, setPaymentDetails] = useState(null);



    const handlePaymentSuccess = (status, orderId, paymentId) => {
   
    setPaymentDetails({ status, orderId, paymentId });

    
    if (status === 200) {
        
        placeOrder({ status, orderId, paymentId });
    } else if (status === 500) {
        setAlertMessage('Payment verification failed. Cannot place the order');
        navigate('/cart');
    } else if (status === undefined) {
        setAlertMessage('Payment status is undefined. Please try again.');
        navigate('/cart');
    } else {
        setAlertMessage('Unexpected status received. Please check the payment process.');
        navigate('/cart');
    }
};
     useEffect(() => {
        if (!isTokenValid()) {
            setAlertMessage('Session expired. Please log in again.');
            navigate('/login');
            return;
        }

        fetchAddresses();

        const tokenExpiryHandler = setInterval(() => {
            if (!isTokenValid()) {
                clearInterval(tokenExpiryHandler);
                setAlertMessage('Session expired. Redirecting to login.');
                navigate('/login');
            }
        }, 60000);

        return () => clearInterval(tokenExpiryHandler);
    }, [navigate]);

    const fetchAddresses = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}/api/addresses/${email}`);
            setAddresses(response.data);
            console.log('fetch address')
             
        } catch (error) {
            console.error('Failed to fetch addresses:', error);
            setAlertMessage('No saved addresses found. Please add an address from your dashboard.');
            navigate('/user-dashboard');
        }
    };
    

   
    
    

    const states = [
    'Andhra Pradesh','Arunachal Pradesh','Assam','Bihar','Chhattisgarh','Goa','Gujarat','Haryana','Himachal Pradesh','Jharkhand','Karnataka','Kerala','Madhya Pradesh','Maharashtra',
    'Manipur','Meghalaya','Mizoram','Nagaland','Odisha','Punjab','Rajasthan','Sikkim','Tamil Nadu','Telangana','Tripura','Uttar Pradesh','Uttarakhand','West Bengal','Delhi','Jammu and Kashmir',
    'Ladakh',
  ];

  useEffect(()=>{
        const calculateDeadWeight=()=>{
        let weight=0;
        cart.forEach(item=> {
            const Weight=item.product.weight
            const quantity=item.quant
            console.log(item.product,'product')
            if(Weight==='900 grams'){
                weight+=1*quantity;
            }
            else if(Weight==='1800 grams'){
                weight+=2*quantity
            }
            else if(Weight==='4500 grams'){
                weight+=4.5*quantity
            }
            else if(Weight==='9000 grams'){
                weight+=9*quantity
            }
            else if(Weight==='300 grams'){
                weight+=0.5*quantity;
            }
            else if(Weight==='1 Litre'){
                weight+=1*quantity
            }
            else if(Weight==='500ml'){
                weight+=0.5*quantity
            }
            
        });
        setDeadWeight(weight)
     }
     calculateDeadWeight()

    },[cart])

    useEffect(() => {
      const total = cart.reduce((acc, product) => {

        return acc + (product.product.discountPrice * product.quant);
      }, 0);
      
      
      setSubTotal(total);
      
      
     }, [cart]);


     const applyCoupon = (couponCode) => {
  // Find the coupon in sales based on the code
  
  const coupon = sales.find((c) => c.couponCode === couponCode);

  if (!coupon) {
    setAlertMessage('Invalid coupon code');
    setDiscount(0);
    setDiscountedSubtotal(subtotal);
    return;
  }

  let discount = 0;

  if (coupon.type === 'global') {
    // Global coupon: Check if cart total meets the minimum purchase requirement
    if (subtotal >= coupon.minPurchase) {
      if (coupon.discountType === 'amount') {
        discount = coupon.discount; // Flat discount
      } else if (coupon.discountType === 'percentage') {
        discount = (subtotal * coupon.discount) / 100; // Percentage discount
      }
    } else {
      setAlertMessage(`Cart total must be at least ₹${coupon.minPurchase} to use this coupon.`);
    }
  } else if (coupon.type === 'product') {
    // Product-specific coupon: Check if the product in the cart meets the conditions
    const productInCart = cart.find((product) => product.product._id === coupon.productId);
    if (productInCart) {
      const productTotal = productInCart.product.discountPrice * productInCart.quant;
      if (productTotal >= coupon.minPurchase) {
        if (coupon.discountType === 'amount') {
          discount = coupon.discount; // Flat discount
        } else if (coupon.discountType === 'percentage') {
          discount = (productTotal * coupon.discount) / 100; // Percentage discount
        }
      } else {
        setAlertMessage(`Product total must be at least ₹${coupon.minPurchase} to use this coupon.`);
      }
    } else {
      setAlertMessage('Coupon code not applicable on selected products.');
    }
  }

  // Apply discount if valid
  if (discount > 0) {
    setDiscount(discount);
    setDiscountedSubtotal(subtotal - discount);
    setAlertMessage('Coupon applied successfully!');
  } else {
    setDiscount(0);
    setDiscountedSubtotal(subtotal);
  }
};

     console.log(discountedSubtotal,'discountedSub total')
   
    const handleCouponVisibility=()=>{
        setIsCouponCodeVisible(!isCouponCodeVisible)
    }
    
     const date=new Date() 

     const productDetails=cart.map(product=>({
        
           "product_category": "FMCG", 
            "product_sub_category": "", 
            "product_name": product.product._id==='66beeb80a473757ab338277c'?'Khand':
                            product.product._id==='66beebffa473757ab3382782'?'Khand':
                            product.product._id==='66bde511c2865cb753446b86'?'Khand':
                            product.product._id==='66bde7a7c2865cb753446b8a'?'Khand':
                            product.product._id==='66bde2b5c2865cb753446b81'?'Gur':
                            product.product._id==='66bde370c2865cb753446b83'?'Boora':
                            product.product._id==='66bde603c2865cb753446b88'?'Boora':
                            product.product._id==='66bde8b6c2865cb753446b8c'?'Shakkar':
                            product.product._id==='66beee19a473757ab338278a'?'Mustard Oil':
                            product.product._id==='66eed6edaab22e27c03af0ba'?'Desi Ghee':
                            'miscelleaneous', 
            "product_quantity": product.quant, 
            "each_product_invoice_amount": product.product.discountPrice*product.quant, 
           "each_product_collectable_amount": 0, 
            "hsn": "" 
    }))

    const productsToSaveOrder=cart.map(product=>({
        
           productId: product.product._id,  
            productName: product.product.title,                 
            quantity: product.quant, 
            price:product.product.discountPrice,
            subtotal: product.product.discountPrice*product.quant, 
            
    }))

    const body={
     "shipment_category": "b2c", 
     "warehouse_detail": { 
     "pickup_location_id": 112581, 
     "return_location_id": 112581 
     }, 
     "consignee_detail": { 
     "first_name": shippingAddress.firstName || shippingAddress.firstName, 
     "last_name": shippingAddress.lastName || billingAddress.lastName, 
     "company_name": "", 
     "contact_number_primary": shippingAddress.phone || billingAddress.phone, 
     "contact_number_secondary":"", 
     "email_id": email, 
     "consignee_address": { 
      "address_line1": shippingAddress.streetAddress || billingAddress.streetAddress, 
      "address_line2": shippingAddress.townCity || billingAddress.townCity, 
      "address_landmark":"", 
      "pincode":  shippingAddress.pincode || billingAddress.pincode
    } 
   }, 
    "order_detail": { 
    "invoice_date": date.toISOString(), 
    "invoice_id": `INV-AR-${shippingAddress.phone || billingAddress.phone}-${Math.floor(1000 + Math.random() * 9000).toString()}`, 
    "payment_type": "Prepaid", 
    "shipment_invoice_amount": subtotal, 
    "total_collectable_amount": 0, 
    "box_details": [ 
      { 
        "each_box_dead_weight":deadWeight, 
        "each_box_length": 15, 
        "each_box_width": deadWeight*8, 
        "each_box_height": 25, 
        "each_box_invoice_amount": subtotal , 
        "each_box_collectable_amount": 0, 
        "box_count":1, 
        "product_details":productDetails
      } 
    ], 
    "ewaybill_number": "", 
    "document_detail": { 
      "invoice_document_file": "", 
      "ewaybill_document_file": "" 
    } 
   }
  }
   

       

  const handleAddOrder=async()=>{
    console.log(body,'body in api call')
    // if(!validateForm()){
    //     setAlertMessage('Please check form validations.')
    // }
    // validateForm()
    
    setLoading(true);
    
    try{
    const response=await axios.post(`${BACKEND_URL}/add-single-order`,{
        body:body
    });
    console.log('after adding the order')
    if(response.status===200){
       
        const shippingData=response.data.data;
        const system_order_id=response.data.system_order_id;
        setShippingCost(shippingData.courier_charge);
        setSystemOrderId(system_order_id);
        setCourierId(shippingData.courier.courier_id)
       
        


    }

    
    }catch(err){
        console.log(err)
    }
    finally{
        setLoading(false)
    }
   
  }



  
    

   const placeOrder = async ({ status, orderId, paymentId }) => {
    console.log('Came to order place method');
    setLoading(true); 
    console.log(status,systemOrderId,courierId,'why failing to place order')
    
    if (status === 200 && systemOrderId && courierId) {
        const body = {
            system_order_id: systemOrderId,
            courier_id: courierId,
        };

        try {
            const response = await axios.post(`${BACKEND_URL}/manifest-single-order`, body);

            if (response.status === 200) {
                
                try{
                const awbResponse=await axios.post(`${BACKEND_URL}/get-awb`,
                    {system_order_id:systemOrderId}
                )

                
                const orderData={
                    userId:email,
                    orderStatus:'Placed',
                    paymentStatus:'Completed',
                    customerName:`${billingAddress.firstName} ${billingAddress.lastName}`,
                    emailAddress:email,
                    phoneNumber:billingAddress.phone,
                    shippingAddress:`${shippingAddress.streetAddress || billingAddress.streetAddress} ${shippingAddress.townCity || billingAddress.townCity} ${shippingAddress.state || billingAddress.state} ${shippingAddress.pincode || billingAddress.pincode}`,
                    billingAddress:`${billingAddress.streetAddress || shippingAddress.streetAddress} ${billingAddress.townCity || shippingAddress.townCity} ${billingAddress.state || billingAddress.state} ${billingAddress.pincode || shippingAddress.pincode}`,
                    products:productsToSaveOrder,
                    trackingNumber:awbResponse.data.awb,
                    shippingCost:shippingCost,
                    totalAmount:subtotal+shippingCost,
                    transactionId:paymentId,
                    discounts:`${discount?discount:''}`
                }
                console.log(orderData, 'order data to send to order details')
                const orderDetailsResponse=await axios.post(`${BACKEND_URL}/save-order-details` ,orderData);
                console.log(orderDetailsResponse,'order details response')
                const sendOrderSuccessMail=await axios.post(`${BACKEND_URL}/api/applications/send-order-placed-message`,{
                    name:billingAddress.firstName,
                    email:email,
                    trackingNumber:awbResponse.data.awb
                })
                console.log(sendOrderSuccessMail,'order success mail')
                setAlertMessage('Order placed successfully');

                navigate('/shop');
                cart.forEach(product => {
                    removeFromCart(product.product._id);
                });
                } catch(error){
                    console.log(error,'error in placing order')
                    setAlertMessage('We could not ship to your address at the moment. Please contact us to place your order manually')
                    
                }
            
            } else {
                setAlertMessage('Failed to place order');
            }
        } catch (error) {
            console.error('Error placing order:', error);
            setAlertMessage('Some error occured.');
        } finally {
            setLoading(false); 

        }
    } else if(!systemOrderId || !courierId){
        setAlertMessage('Calculate Shipping Rates before proceeding to Payment')

    }else {
        setLoading(false); // End loading if payment details are not valid
        setAlertMessage('Invalid payment details. Cannot place order.');
    }
};


  console.log(systemOrderId,'syste order id')
 console.log(subtotal,deadWeight)

  console.log(productDetails,'product details to send to add single order')
//   console.log(`INV-AR-${phone}-${Math.floor(1000 + Math.random() * 9000).toString()}`)
  console.log(body,'body')
  
  console.log(date.toISOString())

  console.log(billingAddress,'billing address')
  console.log(shippingAddress,'shipping address')

  const shouldDisableFields = systemOrderId && courierId;
  return (
    <>
    <div className='checkout-container'>
        {loading && <div className="loader"></div>}

        <div className='page-info'>
            <h1>Check Out</h1>
            <div className='page-info-navigation'>
                <div className='page-info-navigation-icons' onClick={()=>{navigate('/')}}><FontAwesomeIcon icon={faHouse}></FontAwesomeIcon></div>
                
                
            </div>
        </div>
        
        <h3 onClick={handleCouponVisibility}>Have a coupon?Click here to enter your code</h3>
        <AlertPopup message={alertMessage} onClose={() => setAlertMessage('')} />
        {isCouponCodeVisible ?(
            
            <div className='couponcode-container'>
             <p>If you have a coupon code,please apply it below.</p>
             <div className='coupon-code'>
                <input
                 type='text'
                 id='coupon'
                 onChange={(e)=>{(setCouponCode(e.target.value))}}
                 placeholder='Coupon Code'
                 />
                 <button onClick={()=>applyCoupon(couponCode)} className='checkout-container-button'>Apply Coupon</button>

             </div>
            </div>
        ):''}
        <div className='checkout-row-contaner'>
        <div className="address-selection">
        <h2>Select Billing and Shipping Address</h2>
        

      <div className="dropdown-section">
        <label htmlFor="billing-address">Billing Address:</label>
        <select
          id="billing-address"
          onChange={(e) => setBillingAddress(addresses.find(address => address._id === e.target.value))}
          value={billingAddress?._id || ""}
          disabled={shouldDisableFields}
        >
          <option value="">Select Billing Address</option>
          {addresses.map((address) => (
            <option key={address._id} value={address._id}>
              {address.firstName} {address.lastName}, {address.streetAddress}, {address.townCity},{address.pincode},{address.phone}
            </option>
          ))}
        </select>
      </div>

      <div className="dropdown-section">
        <label htmlFor="shipping-address">Shipping Address:</label>
        <select
          id="shipping-address"
          onChange={(e) => setShippingAddress(addresses.find(address => address._id === e.target.value))}
          value={shippingAddress?._id || ""}
          disabled={shouldDisableFields}
        >
          <option value="">Select Shipping Address</option>
          {addresses.map((address) => (
            <option key={address._id} value={address._id}>
              {address.firstName} {address.lastName}, {address.streetAddress}, {address.townCity},{address.pincode},{address.phone}
            </option>
          ))}
        </select>
      </div>

      <div className="selected-addresses">
        <h2>Selected Address</h2>
        <p><strong>Billing Address:</strong> {billingAddress.firstName ? `${billingAddress.firstName} ${billingAddress.lastName}, ${billingAddress.streetAddress}, ${billingAddress.townCity}, ${billingAddress.state}, ${billingAddress.pincode},${billingAddress.phone}` : "Not selected"}</p>
        <p><strong>Shipping Address:</strong> {shippingAddress.firstName? `${shippingAddress.firstName} ${shippingAddress.lastName}, ${shippingAddress.streetAddress}, ${shippingAddress.townCity}, ${shippingAddress.state}, ${shippingAddress.pincode},${shippingAddress.phone}` : "Not selected"}</p>
      </div>
        </div>
        
        
        
        <div className='order-details'>
        <button onClick={handleAddOrder} className='checkout-container-button'>Proceed to Pay</button>
         <h5>Your Order</h5>
         <table>
            <thead>
                <tr>
                    <th>Product</th>
                    <th>SubTotal</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                      <td>
                        {cart.map(product=>(
                        <p>
                        {product.product.title} X {product.quant}
                        </p>
                         ))}
                      </td>
                      <td>
                        {cart.map(product=>(
                        <p>
                        {product.product.discountPrice*product.quant}
                        </p>
                         ))}
                      </td>

                   
                </tr>
                <tr>
                    <td>Subtotal</td>
                    <td>{subtotal}</td>
                </tr>
                {discount>0 &&(
                    <tr>
                        <td>Offer Applied</td>
                        <td>{discount}</td>
                    </tr>
                )}
                <tr>
                    <td>Shipping</td>
                    <td>{shippingCost}</td>
                </tr>
                <tr>
                    <td>Total</td>
                    <td>{(discountedSubtotal || subtotal)+shippingCost}</td>
                </tr>
            </tbody>
         </table>
        
         <Payment  onPaymentSuccess={handlePaymentSuccess} amount={subtotal+shippingCost} systemOrderId={systemOrderId} courierId={courierId}/>
        
       </div>
        </div>
        

        
        
        
    </div>
    <Navbar isHomePage={false}/>
    </>
  )
}

export default LoggedInCheckout