// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* AlertPopup.css */
.alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.alert-popup {
    background-color: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    max-width: 400px;
    width: 90%;
    text-align: center;
}

.alert-popup p{
    font-family: 'Poppins',sans-serif;
    font-size: 14px;
}

.alert-popup .close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    color:#e42727;
}

.close-button:hover{
    color:#ffa406;
}`, "",{"version":3,"sources":["webpack://./src/Styles/AlertPopup.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,wCAAwC;IACxC,kBAAkB;IAClB,gBAAgB;IAChB,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,iCAAiC;IACjC,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB","sourcesContent":["/* AlertPopup.css */\n.alert-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1000;\n}\n\n.alert-popup {\n    background-color: white;\n    padding: 1.5rem;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n    position: relative;\n    max-width: 400px;\n    width: 90%;\n    text-align: center;\n}\n\n.alert-popup p{\n    font-family: 'Poppins',sans-serif;\n    font-size: 14px;\n}\n\n.alert-popup .close-button {\n    position: absolute;\n    top: 8px;\n    right: 8px;\n    background: none;\n    border: none;\n    font-size: 1.2rem;\n    cursor: pointer;\n    color:#e42727;\n}\n\n.close-button:hover{\n    color:#ffa406;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
