
import React, { useState, useEffect } from 'react';
import dummyImage from '../../Assets/user-dummy.png';
import axios from 'axios';
import '../../Styles/Reviews.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';

function Reviews() {
  const [allReviews, setAllReviews] = useState([]); // Array of reviews
  const [page, setPage] = useState(1);
  const [limit] = useState(1); // Reviews per page
   const BACKEND_URL=process.env.REACT_APP_BACKEND_URL

  useEffect(() => {
    const fetchAllReviews = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/fetch-reviews`);
        setAllReviews(response.data); // Store all reviews as an array
      } catch (err) {
        console.log(err);
      }
    };

    fetchAllReviews();
  }, []);

  // Calculate paginated reviews for the current page
  const indexOfLastReview = page * limit;
  const indexOfFirstReview = indexOfLastReview - limit;
  const currentReviews = allReviews.slice(indexOfFirstReview, indexOfLastReview);

  const totalPages = Math.ceil(allReviews.length / limit);

  return (
    <div className='reviews'>
      <h1>Testimonials</h1>
      <div className='review-cards'>
        <ul>
          {currentReviews.length > 0 && currentReviews.map(review => (
            <li key={review._id} className='review-card'>
              <img src={review.userPicture || dummyImage} alt='User profile' />
              <h5>{review.username}</h5>
              <p>{review.reviewDescription}</p>
            </li>
          ))}
        </ul>
      </div>
      <div className='scroll-cards'>
        <button
          className='left-scroll'
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        >
          <FontAwesomeIcon icon={faArrowLeftLong} />
        </button>
        <button
          className='right-scroll'
          onClick={() => setPage(page + 1)}
          disabled={page === totalPages}
        >
          <FontAwesomeIcon icon={faArrowRightLong} />
        </button>
      </div>
    </div>
  );
}

export default Reviews;


