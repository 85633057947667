import React from 'react'

import '../../Styles/MakingProcess.css'
import makingProcessImage from '../../Assets/Home-Page/making-process/12.png'
import Khand from '../../Assets/Home-Page/making-process/khand with bowl.png'
import sugarCane from '../../Assets/Home-Page/making-process/9.png'
import milk from '../../Assets/Home-Page/making-process/10.png'
import ghee from '../../Assets/Home-Page/making-process/11.png'
import KhandSugarcane from '../../Assets/Home-Page/making-process/khand for delight taste.png'
import arrow1 from '../../Assets/Home-Page/arrow/2.png'
import arrow2 from '../../Assets/Home-Page/arrow/Image.png'
import arrow3 from '../../Assets/Home-Page/arrow/3.png'
import background from '../../Assets/Home-Page/background .png'



const processes=[{id:0,img:sugarCane,
  description:'Fresh sugarcane juice is extracted from these sugarcanes & undergoes a controlled process to create the Raw Khand.'},
                 {id:1,img:milk,
                  description:'After converted into Chashni, it is purified using Indian A2 Cow milk, to remove impurities.'},
                 {id:2,img:ghee,
                  description:'Next, we blend the syrup with Indian A2 Cow Ghee, giving it a rich, authentic taste without using any chemicals.'},
                 {id:3,img:Khand,
                  description:'Finally, We got the product Aryadhenu Shudh Desi Khand which is pure, natural, unadulterated, sulfur & chemical-free.'},
                  

]
function MakingProcess() {
  return (
    <div className='making-process'>
        <div className='making-process-image'>
          <img src={makingProcessImage} alt='making process'></img>
        </div>
        <div className='making-process-title'>
            <h1>How is our khand-making process different from others?</h1>
          </div>
          <div className='making-process-main'>
            <div className='final-product-image'>
              <img src={KhandSugarcane} alt='khand'/>
            </div>
            
            <div className='making-process-process'>
              <ul>
                
                <div className='making-process-section-1'>
                <li className='making-process-card'>
                  <img src={sugarCane} alt='sugar cane'/>
                  <p>Fresh sugarcane juice is extracted from these sugarcanes & undergoes a controlled process to create the Raw Khand.</p>
                  
                </li>
                <div className='arrows-section-1'>
                <img src={arrow1} alt='arrow' className='process-arrow1'/>
                <img src={arrow2} alt='arrow' className='process-arrow2'/>
                </div>
                 <li className='making-process-card'>
                  <img src={milk} alt='sugar cane'/>
                  <p>After converted into Chashni, it is purified using Indian A2 Cow milk, to remove impurities.</p>
                  
                </li>
                </div>
                <div className='making-process-section-2'>
                  <li className='making-process-card'>
                  <img src={ghee} alt='sugar cane'/>
                  <p>Next, we blend the syrup with Indian A2 Cow Ghee, giving it a rich, authentic taste without using any chemicals.</p>
                  
                </li>
                <div className='arrows-section-2'>
                <img src={arrow3} alt='arrow' className='process-arrow3'/>
                </div>
                 <li className='making-process-card'>
                  <img src={Khand} alt='sugar cane'/>
                  <p>Finally, We got the product Aryadhenu Shudh Desi Khand which is pure, natural, unadulterated, sulfur & chemical-free.</p>
                  
                </li>

                </div>

              </ul>
              {/* <img src={arrow1} alt='arrow' className='process-arrow1'/>
              <img src={arrow2} alt='arrow' className='process-arrow2'/>
              <img src={arrow3} alt='arrow' className='process-arrow3'/> */}
              
              

            </div>
          </div>
          
    </div>
  )
}

export default MakingProcess