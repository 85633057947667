import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import '../../Styles/Distributor/AdminPage.css'
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { AppContext } from '../../AppContext';
import { useNavigate } from 'react-router-dom';




function AdminPage() {
  const [formData, setFormData] = useState([]);
  const [allFormData,setAllFormData]=useState([])
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const {loginStatus}=useContext(AppContext)
  const navigate=useNavigate()
  const [startDate,setStartDate]=useState('')
  const [endDate,setEndDate]=useState('')
  const [category,setCategory]=useState('')
  const [isLoading,setIsLoading]=useState(false)
  const BACKEND_URL=process.env.REACT_APP_BACKEND_URL

  const categories=['C&F Agents','Super Stockist','Dealer, Distributor, Sub Distributor, Wholesaler, Hawker','Retailer']

 


  const fetchFilteredData = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(`${BACKEND_URL}/distributor/form-data-date-filter`, {
        params: { startDate, endDate }
      });
      setAllFormData(response.data.data);
      downloadCSV(response.data.data)

    } catch (err) {
      setError(err.message);
    }
    finally{
      setIsLoading(false)
    }
  };
  
  const fetchCategoryFilteredDate=async()=>{

     setIsLoading(true)
    
      try {
      const response = await axios.get(`${BACKEND_URL}/distributor/form-date-category-filter`, {
        params: { startDate, endDate ,category}
      });
      setAllFormData(response.data.data);
      downloadCSV(response.data.submissions)

    } catch (err) {
      setError(err.message);
      
    }

    finally{
      setIsLoading(false)
    }
  
   }
  useEffect(() => {
    const fetchFormData = async () => {
      setIsLoading(true)
      try {
        
        const response = await axios.get(`${BACKEND_URL}/distributor/form-data`, {
          params: { page, limit },
        });
        setFormData(response.data.data);
        setTotal(response.data.total);
        
      } catch (err) {
        setError(err.message);
        
      }
      finally{
        setIsLoading(false)
      }
    };

    fetchFormData();
    
  }, [page, limit]);


  

  


  const convertToCSV = (data) => {
  if (!data || data.length === 0) return '';

  // Get all unique headers
  const headers = Array.from(new Set(data.flatMap(Object.keys)));
  const csvRows = [];

  // Add header row
  csvRows.push(headers.join(','));

  // Add data rows
  data.forEach(row => {
    csvRows.push(headers.map(header => {
      const value = row[header] !== undefined ? row[header] : ''; // Handle undefined values
      return `"${value.toString().replace(/"/g, '""')}"`; // Escape double quotes
    }).join(','));
  });

  return csvRows.join('\n');
};

// Download CSV file
const downloadCSV = (data, startDate, endDate) => {
  const csv = convertToCSV(data);

  // Create a Blob and URL for the CSV data
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  // Create a link element to download the file
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `distributor_request.csv`);
  
  // Append the link to the document, click it, and then remove it
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // Clean up the object URL
  URL.revokeObjectURL(url);
};



   
  const exportToExcel = (data,filename) => {
    const ws = XLSX.utils.json_to_sheet([data]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    };

    saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), filename);
  };

  const totalPages = Math.ceil(total / limit);
  if(!loginStatus){
    return navigate('/login')
  }
 

  return (

    <div className='admin-page'>
      <h1>Form Submissions</h1>
      <table>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Contact Number</th>
            <th>Email</th>
            <th>Company Name</th>
            <th>GST Number</th>
            <th>Location</th>
            
            <th>Categories</th>
            <th>GSTIN status</th>
            <th>Business Name</th>
            
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {formData.map((data, index) => (
            <tr key={index}>
              <td>{data.firstName}</td>
              <td>{data.lastName}</td>
              <td>{data.contactNumber}</td>
              <td>{data.email}</td>
              <td>{data.companyName}</td>
              <td>{data.gstin}</td>
              <td>{data.location}</td>
              <td>{data.categories}</td>
              <td>{data.gstinStatus}</td>
              <td>{data.legalNameOfBusiness}</td>
              
               <td>
                <button
                  onClick={() => exportToExcel(data, `${data.firstName}_data.xlsx`)}
                >
                  Download
                </button>
              </td>
              
            </tr>

            
          )
          )}
        </tbody>
      </table>
      <div className='download'>
        <label>
        Start Date:
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
      </label>
      <label>
        End Date:
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </label>
               <button
                  onClick={fetchFilteredData}
                >
                  Download 
                </button>
      </div>

      <div className='download'>
        <label>
        Start Date:
        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
      </label>
      <label>
        End Date:
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </label>
      <select
        value={category}
        onChange={(e) => setCategory(e.target.value)}
      >
        <option value="">Select Category</option>
        {categories.map(cat => (
          <option  value={cat}>{cat}</option>
        ))}
      </select>
               <button
                  onClick={fetchCategoryFilteredDate}
                >
                  Download 
                </button>
      </div>
       
      <div className='pagination'>
        <button
          onClick={() => setPage(page - 1)}
          disabled={page === 1}
        >
          Previous
        </button>
        <span>Page {page} of {totalPages}</span>
        <button
          onClick={() => setPage(page + 1)}
          disabled={page === totalPages}
        >
          Next
        </button>
        {isLoading && <div className="loader"></div>}
      </div>
    </div>
  );
}

export default AdminPage;
