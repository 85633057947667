// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.address-selection{
    max-width:600px;
    display: flex;
    
    margin-bottom: 1em;
    flex-direction: column;
    text-align: left;
}

.dropdown-section{
    display: flex;
    width:100%;
    justify-content: space-between;
    margin: 10px 0px;
    
}

.dropdown-section label{
    font-family: 'Poppins',sans-serif;
    font-size: 16px;
}
.dropdown-section select{
    height:30px;
    width:70%;
}
.order-details{
    margin-left: 50px;
}

@media screen and (max-width:768px){
    .dropdown-section select{
        width:60%;
    }

    .order-details tr{
        display: flex;
        flex-direction: row;
    }

    .order-details td{
        display: flex;
        flex-direction: column;
    }
}`, "",{"version":3,"sources":["webpack://./src/Styles/LoggedInCheckout.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;;IAEb,kBAAkB;IAClB,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,UAAU;IACV,8BAA8B;IAC9B,gBAAgB;;AAEpB;;AAEA;IACI,iCAAiC;IACjC,eAAe;AACnB;AACA;IACI,WAAW;IACX,SAAS;AACb;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI;QACI,SAAS;IACb;;IAEA;QACI,aAAa;QACb,mBAAmB;IACvB;;IAEA;QACI,aAAa;QACb,sBAAsB;IAC1B;AACJ","sourcesContent":[".address-selection{\n    max-width:600px;\n    display: flex;\n    \n    margin-bottom: 1em;\n    flex-direction: column;\n    text-align: left;\n}\n\n.dropdown-section{\n    display: flex;\n    width:100%;\n    justify-content: space-between;\n    margin: 10px 0px;\n    \n}\n\n.dropdown-section label{\n    font-family: 'Poppins',sans-serif;\n    font-size: 16px;\n}\n.dropdown-section select{\n    height:30px;\n    width:70%;\n}\n.order-details{\n    margin-left: 50px;\n}\n\n@media screen and (max-width:768px){\n    .dropdown-section select{\n        width:60%;\n    }\n\n    .order-details tr{\n        display: flex;\n        flex-direction: row;\n    }\n\n    .order-details td{\n        display: flex;\n        flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
