import React from 'react'

import '../../Styles/Hero.css'

import HeroCarousel from './HeroCarousel'

function Hero() {
  return (
    <div className='hero-sample'>
        {/* <img src={H1} alt='hero image'></img> */}
        <HeroCarousel/>
    </div>
  )
}

export default Hero