

import React, { useState } from 'react';
import axios from 'axios';
import '../Styles/addProducts.css'; // Reuse the CSS for consistent styling
import AlertPopup from './AlertPopup';

const UpdateProduct = () => {
    const [productId, setProductId] = useState('');
     const BACKEND_URL=process.env.REACT_APP_BACKEND_URL
    const [formData, setFormData] = useState({
        title: '',
        imageUrl: '',
        quotePrice: '',
        discountPrice: '',
        shortDescription: '',
        brand: '',
        weight: '',
        dietType: '',
        package: '',
        category: '',
        longDescription: '',
        rating: '',
        isStockAvailable: false,
        isFeatured: false,
        deadWeight: '',
    });
    const [alertMessage,setAlertMessage]=useState('')

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData({ ...formData, imageUrl: reader.result });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const body = {};
        if (formData.title) body.title = formData.title;
        if (formData.imageUrl) body.imageUrl = formData.imageUrl;
        if (formData.quotePrice) body.quotePrice = formData.quotePrice;
        if (formData.discountPrice) body.discountPrice = formData.discountPrice;
        if (formData.shortDescription) body.shortDescription = formData.shortDescription;
        if (formData.brand) body.brand = formData.brand;
        if (formData.weight) body.weight = formData.weight;
        if (formData.dietType) body.dietType = formData.dietType;
        if (formData.package) body.package = formData.package;
        if (formData.category) body.category = formData.category;
        if (formData.longDescription) body.longDescription = formData.longDescription;
        if (formData.rating) body.rating = formData.rating;
        if (formData.isStockAvailable !== undefined) body.isStockAvailable = formData.isStockAvailable;
        if (formData.isFeatured !== undefined) body.isFeatured = formData.isFeatured;
        if (formData.deadWeight) body.deadWeight = formData.deadWeight;

        try {
            const response = await axios.patch(`${BACKEND_URL}/products?_id=${productId}`, body);
            setAlertMessage(response.data.message);
        } catch (error) {
            console.error('Error updating product:', error);
            setAlertMessage('Failed to update product. Please check the console for more details.');
        }
    };

    return (
        <div className="add-products">
            <AlertPopup message={alertMessage} onClose={() => setAlertMessage('')} />
            <div className="add-products-card">
                <form className="add-products-form" onSubmit={handleSubmit}>
                    <input type="text" placeholder="Product ID *" value={productId} onChange={(e) => setProductId(e.target.value)} required />
                    <input type="text" name="title" placeholder="Title" value={formData.title} onChange={handleChange} />
                    <input type="file" accept="image/*" onChange={handleImageUpload} />
                    <input type="number" name="quotePrice" placeholder="Quote Price" value={formData.quotePrice} onChange={handleChange} />
                    <input type="number" name="discountPrice" placeholder="Discount Price" value={formData.discountPrice} onChange={handleChange} />
                    <input type="text" name="shortDescription" placeholder="Short Description" value={formData.shortDescription} onChange={handleChange} />
                    <input type="text" name="brand" placeholder="Brand" value={formData.brand} onChange={handleChange} />
                    <input type="text" name="weight" placeholder="Weight" value={formData.weight} onChange={handleChange} />
                    <input type="text" name="dietType" placeholder="Diet Type" value={formData.dietType} onChange={handleChange} />
                    <input type="text" name="package" placeholder="Package" value={formData.package} onChange={handleChange} />
                    <input type="text" name="category" placeholder="Category" value={formData.category} onChange={handleChange} />
                    <textarea name="longDescription" placeholder="Long Description" value={formData.longDescription} onChange={handleChange} />
                    <input type="number" name="rating" placeholder="Rating" value={formData.rating} onChange={handleChange} />
                    <div className="checkbox-container">
                        <label>
                            <input type="checkbox" name="isStockAvailable" checked={formData.isStockAvailable} onChange={handleChange} /> Stock Available
                        </label>
                        <label>
                            <input type="checkbox" name="isFeatured" checked={formData.isFeatured} onChange={handleChange} /> Featured
                        </label>
                    </div>
                    <input type="text" name="deadWeight" placeholder="Dead Weight" value={formData.deadWeight} onChange={handleChange} />
                    <button type="submit">Update Product</button>
                </form>
            </div>
        </div>
    );
};

export default UpdateProduct;

