import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import AlertPopup from "../AlertPopup";

const CreateBlog = () => {
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [alertMessage,setAlertMessage]=useState('')

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const newBlog = { title, author, summary, content };
      await axios.post("${BACKEND_URL}/api/blogs/create", newBlog);
      setAlertMessage("Blog created successfully!");
    } catch (error) {
      console.error("Error creating blog:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Create Blog</h2>
      <AlertPopup message={alertMessage} onClose={() => setAlertMessage('')} />
      <input
        type="text"
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        required
      />

      <input
        type="text"
        placeholder="Author"
        value={author}
        onChange={(e) => setAuthor(e.target.value)}
        required
      />

      <input
        type="text"
        placeholder="Summary"
        value={summary}
        onChange={(e) => setSummary(e.target.value)}
        required
      />

      <CKEditor
        editor={ClassicEditor}
        data={content}
        onChange={(event, editor) => {
          const data = editor.getData();
          setContent(data);
        }}
        config={{
          toolbar: [
            "heading", "|",
            "bold", "italic", "underline", "strikethrough", "|",
            "fontSize", "fontFamily", "fontColor", "fontBackgroundColor", "|",
            "bulletedList", "numberedList", "outdent", "indent", "|",
            "alignment", "|",
            "link", "imageUpload", "|",
            "blockQuote", "insertTable", "mediaEmbed", "|",
            "undo", "redo", "removeFormat" // Removing formatting
          ],
          fontSize: {
            options: [10, 12, 14, "default", 18, 20, 24],
            supportAllValues: true
          },
          fontFamily: {
            options: [
              "default",
              "Poppins, sans-serif",
              "Nunito, sans-serif",
              "Arial, sans-serif",
              "Times New Roman, serif"
            ],
            supportAllValues: true
          },
          fontColor: {
            colors: [
              { color: "hsl(0, 0%, 0%)", label: "Black" },
              { color: "hsl(0, 0%, 30%)", label: "Dim gray" },
              { color: "hsl(0, 0%, 60%)", label: "Gray" },
              { color: "hsl(0, 0%, 90%)", label: "Light gray" },
              { color: "hsl(0, 0%, 100%)", label: "White", hasBorder: true },
              { color: "hsl(0, 75%, 60%)", label: "Red" },
              { color: "hsl(30, 75%, 60%)", label: "Orange" },
              { color: "hsl(60, 75%, 60%)", label: "Yellow" },
              { color: "hsl(90, 75%, 60%)", label: "Light green" },
              { color: "hsl(120, 75%, 60%)", label: "Green" },
              { color: "hsl(180, 75%, 60%)", label: "Aquamarine" },
              { color: "hsl(210, 75%, 60%)", label: "Light blue" },
              { color: "hsl(240, 75%, 60%)", label: "Blue" },
              { color: "hsl(270, 75%, 60%)", label: "Purple" }
            ]
          },
          fontBackgroundColor: {
            colors: [
              { color: "hsl(0, 0%, 0%)", label: "Black" },
              { color: "hsl(0, 0%, 30%)", label: "Dim gray" },
              { color: "hsl(0, 0%, 60%)", label: "Gray" },
              { color: "hsl(0, 0%, 90%)", label: "Light gray" },
              { color: "hsl(0, 0%, 100%)", label: "White", hasBorder: true },
              { color: "hsl(0, 75%, 60%)", label: "Red" },
              { color: "hsl(30, 75%, 60%)", label: "Orange" },
              { color: "hsl(60, 75%, 60%)", label: "Yellow" },
              { color: "hsl(90, 75%, 60%)", label: "Light green" },
              { color: "hsl(120, 75%, 60%)", label: "Green" },
              { color: "hsl(180, 75%, 60%)", label: "Aquamarine" },
              { color: "hsl(210, 75%, 60%)", label: "Light blue" },
              { color: "hsl(240, 75%, 60%)", label: "Blue" },
              { color: "hsl(270, 75%, 60%)", label: "Purple" }
            ]
          }
        }}
      />

      <button type="submit">Create Blog</button>
    </form>
  );
};

export default CreateBlog;
