
import React from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
} from 'react-share';
import { Helmet } from 'react-helmet';
import '../Styles/ShareComponent.css'

const ShareComponent = ({ url, title,imageUrl }) => {
  return (
    <div className='share-component'>
       <Helmet>
        <meta property="og:title" content={title} />
        
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={url} />
        
        <meta name="twitter:title" content={title} />
       
        <meta name="twitter:image" content={imageUrl} />
      </Helmet>
      <h3>Share :</h3>
      <div className='share-component-icons'>
      <FacebookShareButton url={url} quote={title} style={{ marginLeft: '8px' }}>
        <FacebookIcon size={20} round />
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title} style={{ marginLeft: '8px' }}>
        <TwitterIcon size={20} round />
      </TwitterShareButton>
      <WhatsappShareButton url={url} title={title} style={{ marginLeft: '8px' }}>
        <WhatsappIcon size={20} round />
      </WhatsappShareButton>
      {/* <LinkedinShareButton url={url} title={title} summary={title} source="Your Website">
        <LinkedinIcon size={32} round />
      </LinkedinShareButton> */}
      </div>
    </div>
  );
};

export default ShareComponent;
