import React, { useState } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import '../Styles/RequestResetForm.css'


const BACKEND_URL=process.env.REACT_APP_BACKEND_URL

const RequestResetForm = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${BACKEND_URL}/request-password-reset`, { email });
            setMessage(response.data.message);
        } catch (error) {
            setMessage('Error sending reset link');
        }
    };

    return (
        <>
        <div className='request-password-reset'>
            <div className='password-reset-card'>
            <h2>Request Password Reset</h2>
            <form onSubmit={handleSubmit}>
                <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                <button type="submit" className='request-reset-button'>Request Reset</button>
                <a href='/login'>Back to Login</a>
            </form>
            <p className='error-message'>{message}</p>
            </div>
        </div>
        <Navbar isHomePage={false}/>
        </>
    );
};

export default RequestResetForm;
