
import React, { useState, useEffect, useContext,useMemo ,useRef} from 'react';
import { AppContext } from '../AppContext';
import axios from 'axios';
import '../Styles/ShopProducts.css';
import Navbar from '../Components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faIndianRupeeSign, faCartShopping } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitch, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { useCart } from '../CartContext';
import Carousel from './Carousal';
import dummyImage from '../Assets/user-dummy.png';
// import ReactImageMagnify from 'react-image-magnify';
import { useNavigate, useParams } from 'react-router-dom';
import CartPopup from './CartPopup';
import KhandOne from '../Assets/images/khand/KhandOne.jpg'
import KhandTwo from '../Assets/images/khand/KhandTwo.jpg'
import KhandThree from '../Assets/images/khand/KhandThree.jpg'
import GurOne from '../Assets/images/gur/GurOne.jpg'
import GurTwo from '../Assets/images/gur/GurTwo.jpg'
import GurThree from '../Assets/images/gur/GurThree.jpg'
import ShakkarOne from '../Assets/images/shakkar/ShakkarOne.jpg'
import ShakkarTwo from '../Assets/images/shakkar/ShakkarTwo.jpg'
import ShakkarThree from '../Assets/images/shakkar/ShakkarThree.jpg'
import mustardOne from '../Assets/images/Mustard Oil/mustardOne.jpg'
import mustardTwo from '../Assets/images/Mustard Oil/mustardTwo.jpg'
import mustardThree from '../Assets/images/Mustard Oil/mustardThree.jpg'
import ProductCarousel from './ProductCarousel';
import ShareComponent from '../Components/ShareComponent'
import { Helmet } from 'react-helmet';


import { faArrowLeftLong, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';


const imagesData={
    "66bde511c2865cb753446b86":[KhandOne,KhandTwo,KhandThree],
    "66bde7a7c2865cb753446b8a":[KhandOne,KhandTwo,KhandThree],
    "66beeb80a473757ab338277c":[KhandOne,KhandTwo,KhandThree],
    "66beebffa473757ab3382782":[KhandOne,KhandTwo,KhandThree],
    "66bde2b5c2865cb753446b81":[GurOne,GurTwo,GurThree],
    "66bde8b6c2865cb753446b8c":[ShakkarOne,ShakkarTwo,ShakkarThree],
    "66beee19a473757ab338278a":[mustardOne,mustardTwo,mustardThree]

}

const isTokenValid = () => {
    const token = localStorage.getItem('token');
    const expiry = localStorage.getItem('tokenExpiry');
    return token && new Date().getTime() < expiry;
};



function ShopProducts() {
    const [messages, setMessages] = useState({});
    const { products, setProducts } = useContext(AppContext);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const { addToCart } = useCart();
    const [isDescriptionActive, setIsDescriptionActive] = useState(true);
    const [isReviewActive, setIsReviewActive] = useState(false);
    const [isSpecificationActive,setIsSpecificationActive]=useState(false)
    const [reviews, setReviews] = useState([]);
    
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const { productId } = useParams(); // Get productId from URL
    const navigate = useNavigate();
    const [currentPage,setCurrentPage]=useState(0)
    const [imageData,setImageData]=useState([])
    const [discountPercentage,setDiscountPercentage]=useState(null)
    const [currentReviewPage,setCurrentReviewPage]=useState(1)
    const [reviewsPerPage,setReviewsPerPage]=useState(null)
     const BACKEND_URL=process.env.REACT_APP_BACKEND_URL
     const [isLoading,setIsLoading]=useState(true)
      const [currentReviews, setCurrentReviews] = useState([]);
      const [paginatedReviews, setPaginatedReviews] = useState([]);
      const [start,setStart]=useState(0);
      const [end,setEnd]=useState(3)

     


    console.log(productId,'product id')

    

    useEffect(() => {
        const fetchProducts = async () => {
            setIsLoading(true);
            try {
                const response = await axios.get(`${BACKEND_URL}/getProduct`);
                if (response.status === 200 || response.status === 201) {
                    const data = response.data;
                    // setProducts(data);
                    
                    // Find the current product based on productId from URL
                    const foundProduct = products.find(product => product._id === productId);
                    setCurrentProduct(foundProduct );
                    
                    if (foundProduct) {
                      const relatedImages = imagesData[foundProduct._id] || []; 
                
                
                      const imagesWithCurrent = [foundProduct.imageUrl, ...relatedImages]; 
                
                      setImageData(imagesWithCurrent);
                      
                    }
                } else {
                    setMessages({ error: response.message });
                }
            } catch (error) {
                setMessages({ error: { error } });
            } 
        };
        
        const storedProducts = sessionStorage.getItem('products');
        if (storedProducts) {
            const parsedProducts = JSON.parse(storedProducts);
            setProducts(parsedProducts);
            const foundProduct = parsedProducts.find(product => product._id === productId);
            setCurrentProduct(foundProduct ); // Fallback to first product if not found

            if (foundProduct) {
                      const relatedImages = imagesData[foundProduct._id] || []; 
                
                
                      const imagesWithCurrent = [foundProduct.imageUrl, ...relatedImages]; 
                
                      setImageData(imagesWithCurrent);
                      console.log(imageData,'image data')
                    }
            
        } else {
            fetchProducts();
        }
        const discountPercentage=calculateDiscountPercentage()
        setDiscountPercentage(discountPercentage)
        
    }, [productId,setProducts]);

   

    console.log(imageData,'image data')


    const handleNextPage = () => {
    setCurrentPage((prevPage) => {
        const nextPage = Math.min(prevPage + 1, products.length - 1);
        const nextProduct = products[nextPage];
        setCurrentProduct(nextProduct);
        navigate(`/shop/${nextProduct._id}`); // Navigate to the product detail page
        return nextPage;
    });
};

const handlePreviousPage = () => {
    setCurrentPage((prevPage) => {
        const prevPageIndex = Math.max(prevPage - 1, 0);
        const prevProduct = products[prevPageIndex];
        setCurrentProduct(prevProduct);
        navigate(`/shop/${prevProduct._id}`); // Navigate to the product detail page
        return prevPageIndex;
    });
};
    

    useEffect(() => {
        const fetchReviews = async () => {
            setIsLoading(true)
            try {
                console.log('making an api call')
                const response = await axios.get(`${BACKEND_URL}/fetch-reviews`);
                setReviews(response.data);
                console.log(response,'review response')
            } catch (err) {
                console.log(err);
            }
            finally{
                // setIsLoading(false)
            }
        };
       

        fetchReviews();
    }, [productId]);

    

    console.log(reviews,'all reviews')
    
    
     const topReviews = useMemo(() => {
        
        return reviews
            .filter(review => review.productId === productId)
            .sort((a, b) => new Date(b.reviewDate) - new Date(a.reviewDate));
    }, [reviews, productId]);

        console.log(topReviews,'top product reviews')

    const handleQuantityChange = (change) => {
        setQuantity(prevQuantity => {
            const newQuantity = prevQuantity + change;
            if (newQuantity < 1) return 1;
            if (newQuantity > (currentProduct ? currentProduct.stock : 1)) return currentProduct ? currentProduct.stock : 1;
            return newQuantity;
        });
    };

    const handleAddToCart = () => {
        if (currentProduct) {
            addToCart(currentProduct, quantity);
            setIsPopupVisible(true);
        }
    };

    const relatedProducts = products.filter(product => product._id !== currentProduct?._id );

    const navigateToCheckout = () => {
        handleAddToCart();
        if(isTokenValid()){
        navigate('/login-checkout')
     }else{
        navigate('/checkout')
     }
     
    };

    const onClose = () => {
        setIsPopupVisible(false);
    };

 const calculateDiscountPercentage = () => {
        
      if (currentProduct && currentProduct.quotePrice && currentProduct.discountPrice) {
      const discount = ((currentProduct.quotePrice - currentProduct.discountPrice) / currentProduct.quotePrice) * 100;
      return discount > 0 ? discount.toFixed(1) : null; // Limit to 1 decimal
    }
    return null;
    
  };  

  


//   useEffect(() => {
//     const handleResize = () => {
//       setReviewsPerPage(window.innerWidth <= 768 ? 1 : Math.min(topReviews.length,3)); // 1 blog per page for screens <= 768px (mobile)
//     };

//     handleResize(); // Call once on mount
//     window.addEventListener('resize', handleResize); // Listen for resize events

//     return () => window.removeEventListener('resize', handleResize); // Cleanup on unmount
//   }, []);
 

//   useEffect(() => {
//         if (topReviews.length > 0) {
//             // When topReviews are ready, calculate the slice and set loading to false
//             const start = (currentReviewPage - 1) * reviewsPerPage;
//             const end = start + reviewsPerPage;
//             setCurrentReviews(topReviews.slice(start, end));
            
//         }
//     }, [topReviews, currentReviewPage,isReviewActive]);
    const totalPages = Math.ceil(topReviews.length / reviewsPerPage);
    

    console.log(currentReviews,'current product reviews')

    

    
    const handleWriteReview = () => {
        navigate(`/product/${productId}/review`,{state:{topReviews}}); // Redirect to protected route for reviews
    };
   
    

// const handleReviewNext = () => {
    
//     setStart(start+3)
//     setEnd(end+3)
// };

// const handleReviewPrevious = () => {
    
//       setStart(start-3)
//       setEnd(end-3)
// };

 useEffect(() => {
        const handleResize = () => {
            const isMobile = window.innerWidth < 768;
            setReviewsPerPage(isMobile ? 1 : 3);
            setStart(0);
            setEnd(isMobile ? 1 : 3);
        };

        handleResize(); // Call on mount
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize); // Clean up listener on unmount
    }, []);

    const handleReviewNext = () => {
        const increment = window.innerWidth < 768 ? 1 : 3;
        const nextStart = start + increment;
        const nextEnd = end + increment;
        if (nextStart < topReviews.length) {
            setStart(nextStart);
            setEnd(nextEnd);
        }
    };

    const handleReviewPrevious = () => {
        const decrement = window.innerWidth < 768 ? 1 : 3;
        const prevStart = Math.max(0, start - decrement);
        const prevEnd = Math.max(reviewsPerPage, end - decrement);
        setStart(prevStart);
        setEnd(prevEnd);
    };

const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  const [isZoomed, setIsZoomed] = useState(false);
  const imageContainerRef = useRef(null);

  const handleMouseMove = (e) => {
    if (!isZoomed) return;

    const { left, top, width, height } = imageContainerRef.current.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setZoomPosition({ x, y });
  };

  const handleMouseEnter = () => setIsZoomed(true);
  const handleMouseLeave = () => setIsZoomed(false);

  const pageUrl=window.location.href;
  const title=currentProduct?currentProduct.title:'';
 
  const imageUrl=currentProduct?currentProduct.imageUrl:''

  console.log(pageUrl,'page url')
  console.log(title,'title')


   useEffect(() => {
        
         if(currentProduct && reviews){
            setIsLoading(false)
        }
    }, [currentProduct,reviews]);

  
   
  

    return (
        <div>
       
                {isLoading ? <div className='loader'></div>:(
                <>  
                     <Helmet>
        <title>{title} - Discover this amazing product!</title>
        <meta property="og:title" content={title} />
        
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={pageUrl} />

        
        <meta name="twitter:title" content={title} />
        
        <meta name="twitter:image" content={imageUrl} />
        </Helmet>
                    <div className='product-container'>
                        <div className='mobile-navigation-buttons'>
                         <button onClick={handlePreviousPage} disabled={currentPage === 0}>&lt;</button>
                         <button onClick={handleNextPage} disabled={currentPage === products.length - 1}>&gt;</button>
                        </div>
                        <div className='product-image'
                         
                        >
                            {/* {currentProduct &&(
                                <img src={currentProduct.imageUrl} alt='product '/>

                                 
                            )} */}

                            {imageData.length > 0 ? (
                            <ProductCarousel images={imageData} /> // Use your Carousel component here
                        ) : currentProduct && (
                        
                            // // <img src={currentProduct?.imageUrl || dummyImage} alt='product' />
                            <div
                                className="zoomable-image-container"
                                ref={imageContainerRef}
                                onMouseMove={handleMouseMove}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <img
                                src={currentProduct?.imageUrl }
                                alt=''
                                style={{
                                transformOrigin: `${zoomPosition.x}% ${zoomPosition.y}%`,
                                transform: isZoomed ? `scale(${3})` : 'scale(1)',
                                }}
                                />
                            </div>
                        )}
                         
                            
                        </div>
                        <div className='product-details-container'>
                            {currentProduct && (
                                
                                <div>
                                    <h3>{currentProduct.title}</h3>
                                
                                    <p>{currentProduct.shortDescription}</p>
                                    <div className='write-review'>
                                        <p onClick={handleWriteReview} ><FontAwesomeIcon icon={faPencil} /> Write a Review</p>
                                    </div>
                                    <div className='price-container'>
                                        <p style={{ color: '#E42727', fontWeight: 700,fontSize:'20px' }}>
                                            <span>{discountPercentage && -discountPercentage}{discountPercentage && '%'}</span><FontAwesomeIcon icon={faIndianRupeeSign} />{currentProduct.discountPrice}
                                        </p>
                                        <p style={{marginRight:'10px',fontSize:'12px'}}>MRP</p>
                                        <p style={{ textDecoration: 'line-through' }}>
                                            <FontAwesomeIcon icon={faIndianRupeeSign} />{currentProduct.quotePrice}
                                        </p>
                                        
                                    </div>
                                    <p className='taxes-info'>Inclusive of all Taxes</p>
                                    
                                    <span className="rating-stars" style={{ textAlign: 'left', display: 'block' }}>{currentProduct.rating!==0?Math.ceil(currentProduct.rating):''}{'⭐'.repeat(currentProduct.rating)}</span>
                                    <div className='additional-details'>
                                        <h5>Brand :</h5>
                                        <p>{currentProduct.brand}</p>
                                    </div>
                                    <div className='additional-details'>
                                        <h5>Category :</h5>
                                        <p>{currentProduct.category}</p>
                                    </div>
                                    <div className='additional-details'>
                                        <h5>Diet Type :</h5>
                                        <p>{currentProduct.dietType}</p>
                                    </div>
                                    <div className='additional-details'>
                                        <h5>Weight :</h5>
                                        <p>{currentProduct.weight}</p>
                                    </div>
                                    <div className='additional-details'>
                                        <h5>Package Type :</h5>
                                        <p>{currentProduct.package}</p>
                                        
                                    </div>
                                    <div className='additional-details'>
                                        <h5>In-Stock :</h5>
                                        <p>{currentProduct && currentProduct.isStockAvailable?'Yes':'No'}</p>
                                        
                                    </div>
                                    <div className='additional-details'>
                                        {/* <h5>Share :</h5>
                                        <p>
                                        <button className="share-button"  >
                                            <FontAwesomeIcon icon={faFacebook} /> 
                                        </button>
                                        <button className='share-button'>
                                            <FontAwesomeIcon icon={faTwitch}/>
                                        </button>
                                        <button className='share-button'>
                                            <FontAwesomeIcon icon={faWhatsapp} />
                                        </button>
                                        </p> */}
                                         {pageUrl && title && imageUrl && <ShareComponent url={pageUrl} title={title}  imageUrl={imageUrl}/> }
                                    </div>
                                   
                                    
                                </div>
                                
                                
                            )}
                            <div className='cart-container'>
                                <h3>Quantity</h3>
                                <div className='cart-quantity-control'>
                                    <button onClick={() => handleQuantityChange(-1)} disabled={quantity <= 1}>-</button>
                                    <p>{quantity}</p>
                                    <button onClick={() => handleQuantityChange(1)}>+</button>
                                </div>
                                <div className='cart-buttons'>
                                    <button onClick={handleAddToCart} className='cart-button'   >
                                        <FontAwesomeIcon icon={faCartShopping} style={{ color: '#ffffff' }} /> Add to Cart
                                    </button>

                                    {isPopupVisible && (
                                        <CartPopup
                                            product={currentProduct}
                                            onCheckout={navigateToCheckout}
                                            onClose={onClose}
                                            quantity={quantity}
                                        />
                                    )}
                                    <button className='buy-button' onClick={navigateToCheckout} >Buy it now</button>
                                </div>
                            </div>
                        </div>
                  <div className='navigation-buttons'>
                     <button onClick={handlePreviousPage} disabled={currentPage === 0}>&lt;</button>
                    <button onClick={handleNextPage} disabled={currentPage === products.length - 1}>&gt;</button>
                  </div>
                    </div>
                    <div className='description-reviews-container'>
                        <div className='description-review-options'>
                            <h3 className={isDescriptionActive ? 'description-active' : ''} onClick={() => { setIsDescriptionActive(true); setIsReviewActive(false);setIsSpecificationActive(false) }}>Description</h3>
                            <h3 className={isReviewActive ? 'review-active' : ''} onClick={() => { setIsDescriptionActive(false); setIsReviewActive(true);setIsSpecificationActive(false) }}>Reviews</h3>
                            <h3 className={isSpecificationActive?'specification-active':''} onClick={()=>{setIsDescriptionActive(false); setIsReviewActive(false);setIsSpecificationActive(true)}}>Specifications</h3>
                        </div>
                        <div className='description-content'>
                            {isDescriptionActive && currentProduct && <p>{currentProduct.longDescription?currentProduct.longDescription:'No Description'}</p>}
                        </div>
                        <div className='reviews-content'>
                            {isReviewActive && topReviews && <>{topReviews.length>0? topReviews.slice(start,end).map(review => (
                                <div className='reviews-card' key={review._id}>
                                    <div className='review-image'>
                                        <img src={review.userPicture || dummyImage} alt='review user' />
                                    </div>
                                    <div className='review-content'>
                                        <span className="review-stars">{'⭐'.repeat(review.stars)}</span>
                                        <p>{review.username}</p>
                                        <p>{review.reviewDescription}</p>
                                    </div>
                                </div>
                            )):<p>No reviews</p>}</>}
                           
                        </div>
                         {isReviewActive && totalPages > 1 && (
                        

                        <div className='scroll-cards'>
                             <button
                                  className='left-scroll'
                                  onClick={handleReviewPrevious} disabled={start===0}
                                >
                        <FontAwesomeIcon icon={faArrowLeftLong} />
                        </button>
                          <button
                           className='right-scroll'
                           onClick={handleReviewNext} disabled={end>=topReviews.length}
                           >
                          <FontAwesomeIcon icon={faArrowRightLong} />
                         </button>
                         </div>

                          
                          )}
                    </div>
                    <Carousel cards={relatedProducts} title="Related Products" />
                </>
                )}
            
            <Navbar />
        </div>
    );
}

export default ShopProducts;


