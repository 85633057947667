import React from 'react'
import '../Styles/About.css'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse, faGreaterThan} 
from '@fortawesome/free-solid-svg-icons'
import Navbar from './Navbar'
import about from '../Assets/AboutPage.jpeg'

function About() {
    const navigate=useNavigate()
  return (
    <div className='about-container'>
        <div className='page-info'>
            <h1>About</h1>
            <div className='page-info-navigation'>
                <div className='page-info-navigation-icons' onClick={()=>{navigate('/')}}><FontAwesomeIcon icon={faHouse}></FontAwesomeIcon></div>
                

            </div>
            <div className='about-content'>
                <img src={about} alt='about'/>
                <div className='about-content-text'>
                    <p>Aryadhenu is an Institution that is into producing Natural, Healthy and Chemical Free Products. 
                        We take pride in using absolutely natural products and give India its legacy of using Natural Products.
                         We take pride in its collaboration with farmers who have inherited a talent pool from their families. 
                         Along with these farmers and our agronomists, we then put experience and education to some brilliant use. 
                         We believe that our consistency is the best industry practice. And love for natural sweeteners is what continues
                          to be best for you.</p>
                    <p>Aryadhenu is an Institution that is into producing Natural, Healthy and Chemical Free Products. 
                        We take pride in using absolutely natural products and give India its legacy of using Natural Products.
                         We take pride in its collaboration with farmers who have inherited a talent pool from their families. 
                         Along with these farmers and our agronomists, we then put experience and education to some brilliant use. 
                         We believe that our consistency is the best industry practice. And love for natural sweeteners is what continues
                          to be best for you.</p>
                    <p>Aryadhenu is an Institution that is into producing Natural, Healthy and Chemical Free Products. 
                        We take pride in using absolutely natural products and give India its legacy of using Natural Products.
                         We take pride in its collaboration with farmers who have inherited a talent pool from their families. 
                         Along with these farmers and our agronomists, we then put experience and education to some brilliant use. 
                         We believe that our consistency is the best industry practice. And love for natural sweeteners is what continues
                          to be best for you.</p>

                    <p>Aryadhenu is an Institution that is into producing Natural, Healthy and Chemical Free Products. 
                        We take pride in using absolutely natural products and give India its legacy of using Natural Products.
                         We take pride in its collaboration with farmers who have inherited a talent pool from their families. 
                         Along with these farmers and our agronomists, we then put experience and education to some brilliant use. 
                         We believe that our consistency is the best industry practice. And love for natural sweeteners is what continues
                          to be best for you.</p>
                    
                          


                </div>

            </div>
        </div>

        <Navbar isHomePage={false}/>
    </div>
  )
}

export default About