import React ,{useState,useRef} from 'react';
import '../Styles/ProductCarousel.css'; // Add your styles here



const ProductCarousel = ({ images }) => {
    const [currentIndex, setCurrentIndex] = React.useState(0);

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  const [isZoomed, setIsZoomed] = useState(false);
  const imageContainerRef = useRef(null);

  const handleMouseMove = (e) => {
    if (!isZoomed) return;

    const { left, top, width, height } = imageContainerRef.current.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setZoomPosition({ x, y });
  };

  const handleMouseEnter = () => setIsZoomed(true);
  const handleMouseLeave = () => setIsZoomed(false);

    return (
        <div className="product-carousel">
            {images.length > 0 && (
                <>
                    <div className="carousal-container">
                        {/* <img
                            src={images[currentIndex]}
                            alt={`Product ${currentIndex + 1}`}
                            className="carousel-image"
                        /> */}
                        <div
                        className="zoomable-image-container"
                             ref={imageContainerRef}
                            onMouseMove={handleMouseMove}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                         >
      <img
        src={images[currentIndex]?images[currentIndex]:images[0]}
        alt=''
        style={{
          transformOrigin: `${zoomPosition.x}% ${zoomPosition.y}%`,
          transform: isZoomed ? `scale(${3})` : 'scale(1)',
        }}
      />
      </div>
                       
                    </div>
                    <div className="carousel-buttons">
                        <button onClick={handlePrevious} className="carousel-button">
                            &lt;
                        </button>
                        <button onClick={handleNext} className="carousel-button">
                            &gt;
                        </button>
                    </div>
                    <div className="carousel-indicators">
                        {images.map((_, index) => (
                            <span
                                key={index}
                                className={`indicator ${index === currentIndex ? 'active' : ''}`}
                                onClick={() => setCurrentIndex(index)}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default ProductCarousel;
