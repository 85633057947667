
import React from 'react';
import '../Styles/AlertPopup.css';

const AlertPopup = ({ message, onClose }) => {
  if (!message) return null; // If no message, don't render anything

  return (
    <div className="alert-overlay">
      <div className="alert-popup">
        <button className="close-button" onClick={onClose}>✕</button>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default AlertPopup;
