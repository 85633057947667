import React, { useState } from 'react';
import axios from 'axios';
import '../Styles/SaleForm.css'
import AlertPopup from './AlertPopup';
 const BACKEND_URL=process.env.REACT_APP_BACKEND_URL

const SaleForm = () => {
  const [formData, setFormData] = useState({
    couponCode: '',
    type: 'global',
    discount: '',
    discountType: '', 
    minPurchase: '',
    productId: '',
    startDate: '',
    endDate: ''
  });
  const [alertMessage,setAlertMessage]=useState('')

  const [error, setError] = useState('');

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // If the sale type is 'product', ensure productId is provided
    if (formData.type === 'product' && !formData.productId) {
      setError('Product ID is required for product sales');
      return;
    }

    
    const startDate = new Date(formData.startDate).toISOString();
    const endDate = new Date(formData.endDate).toISOString();

    const newFormData = {
      ...formData,
      startDate,
      endDate
    };

    try {
      
      const response = await axios.post(`${BACKEND_URL}/sale/create-sale`, newFormData);
      setAlertMessage(response.data.message); 
      setFormData({
         couponCode: '',
         type: 'global',
        discount: '',
        discountType: '', 
        minPurchase: '',
        productId: '',
        startDate: '',
        endDate: ''
      })
    } catch (error) {
      setError(error.response ? error.response.data.error : 'An error occurred');
    }
  };

  return (
    <div className='sale-form-container'>
        <AlertPopup message={alertMessage} onClose={() => setAlertMessage('')} />
      <h2>Create a New Sale</h2>
      <form onSubmit={handleSubmit}>
        <div className='sale-input-group'>
          <label>Coupon Code :</label>
          <input 
            type="text" 
            name="couponCode" 
            value={formData.couponCode} 
            onChange={handleChange} 
            required
          />
        </div>

        <div className='sale-input-group'>
          <label>Sale Type :</label>
          <select name="type" value={formData.type} onChange={handleChange}>
            <option value="global">Global Sale</option>
            <option value="product">Product Sale</option>
          </select>
        </div>
        <div className='sale-input-group'>
          <label>Discount Type :</label>
          <select name="discountType" value={formData.discountType} onChange={handleChange} required>
            <option value="">Select Discount Type</option>
            <option value="percentage">Percentage</option>
            <option value="amount">Amount</option>
          </select>
        </div>
        <div className='sale-input-group'>
          <label>Discount :</label>
          <input 
            type="number" 
            name="discount" 
            value={formData.discount} 
            onChange={handleChange} 
            required
          />
        </div>

        

        <div className='sale-input-group'>
          <label>Minimum Purchase :</label>
          <input 
            type="number" 
            name="minPurchase" 
            value={formData.minPurchase} 
            onChange={handleChange} 
            required
          />
        </div>

        {/* Conditionally render product ID field for product sale type */}
        {formData.type === 'product' && (
          <div className='sale-input-group'>
            <label>Product ID :</label>
            <input 
              type="text" 
              name="productId" 
              value={formData.productId} 
              onChange={handleChange} 
              required
            />
          </div>
        )}

        <div className='sale-input-group'>
          <label>Start Date :</label>
          <input 
            type="datetime-local" 
            name="startDate" 
            value={formData.startDate} 
            onChange={handleChange} 
            required
          />
        </div>

        <div className='sale-input-group'>
          <label>End Date :</label>
          <input 
            type="datetime-local" 
            name="endDate" 
            value={formData.endDate} 
            onChange={handleChange} 
            required
          />
        </div>

        {error && <p style={{ color: 'red' }}>{error}</p>}
        
        <button type="submit">Create Sale</button>
      </form>
    </div>
  );
};

export default SaleForm;

