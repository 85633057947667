import React, { useState ,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import '../../Styles/Home.css'
import { useNavigate } from 'react-router-dom';

const BlogPagination = ({ blogs }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [blogsPerPage,setBlogsPerPage] = useState(3);
  const navigate=useNavigate()
  
   useEffect(() => {
    const handleResize = () => {
      setBlogsPerPage(window.innerWidth <= 768 ? 1 : 3); // 1 blog per page for screens <= 768px (mobile)
    };

    handleResize(); // Call once on mount
    window.addEventListener('resize', handleResize); // Listen for resize events

    return () => window.removeEventListener('resize', handleResize); // Cleanup on unmount
  }, []);
  

  // Calculate the index of the first and last blog to display
  const startIndex = currentPage * blogsPerPage;
  const endIndex = startIndex + blogsPerPage;
  const currentBlogs = blogs.slice(startIndex, endIndex);

  const handleScroll = (direction) => {
    const newPage = currentPage + direction;
    // Ensure new page is within bounds
    if (newPage >= 0 && newPage < Math.ceil(blogs.length / blogsPerPage)) {
      setCurrentPage(newPage);
    }
  };
  const handleReadMore = (blogId) => {
        navigate(`/blogs/${blogId}`);  // Adjust based on your routing setup
    };

  return (
    <div className='blogs'>
      <h1 style={{ color: '#E42727' }}>Blog Posts</h1>
      <ul>
        {currentBlogs.map((blog) => (
          <li key={blog._id} className='blog-post' onClick={() => handleReadMore(blog._id)}>
            <img src={blog.image} alt={blog.title} />
            <h4>{blog.title}</h4>
            <p>
              {blog.summary} <a style={{ color: 'red' }}>
                <button onClick={() => handleReadMore(blog._id)} style={{ color: 'red', background: 'none', border: 'none', cursor: 'pointer' }}>
                Read more
              </button>
              </a>
            </p>
          </li>
        ))}
      </ul>
      <div className='scroll-cards'>
        <button className='left-scroll' onClick={() => handleScroll(-1)}>
          <FontAwesomeIcon icon={faArrowLeftLong} />
        </button>
        <button className='right-scroll' onClick={() => handleScroll(1)}>
          <FontAwesomeIcon icon={faArrowRightLong} />
        </button>
      </div>
    </div>
  );
};

export default BlogPagination;
