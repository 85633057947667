import React, { createContext, useContext ,useEffect,useState} from 'react'
import { useCart } from '../CartContext';
import '../Styles/Cart.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCartShopping,faArrowRightLong,faArrowLeftLong,faIndianRupeeSign,faTruckFast,faHeadset,faTrash, faHouse, faGreaterThan} 
from '@fortawesome/free-solid-svg-icons'
import Payment from './Payment';
import Navbar from '../Components/Navbar'
import { useOrder } from '../OrderContext';
import axios  from 'axios'
import { useNavigate ,Link} from 'react-router-dom';
import { AppContext } from '../AppContext';

const isTokenValid = () => {
    const token = localStorage.getItem('token');
    const expiry = localStorage.getItem('tokenExpiry');
    return token && new Date().getTime() < expiry;
};

function Cart() {
    const {cart,incrementQuantity,decrementQuantity,removeFromCart,
        subtotal,setSubTotal,deadWeight,setDeadWeight,shippingCost,setShippingCost,pincode,setPincode
    } = useCart()
    
    const [isAddressChange,setIsAddressChange]=useState(false)
    const [shippingState,setShippingState]=useState('Haryana')
    const [code,setCode]=useState('')
     const BACKEND_URL=process.env.REACT_APP_BACKEND_URL
    const {loginStatus}=useContext(AppContext)
    

    const navigate=useNavigate()

    const handleCheckoutNavigation=()=>{
     if(isTokenValid()){
        navigate('/login-checkout')
     }else{
        navigate('/checkout')
     }
     
    }

    useEffect(()=>{
        const calculateDeadWeight=()=>{
        let weight=0;
        cart.forEach(item=> {
            const Weight=item.product.weight
            const quantity=item.quant
            console.log(item.product,'product')
            if(Weight==='900 grams'){
                weight+=1*quantity;
            }
            else if(Weight==='300 grams'){
                weight+=0.5*quantity;
            }
            else if(Weight==='1 Litre'){
                weight+=1*quantity
            }
            else if(Weight==='500ml'){
                weight+=0.5*quantity
            }
            
        });
        setDeadWeight(weight)
     }
     calculateDeadWeight()

    },[cart])
   

   console.log(deadWeight,'deadWeight  g')
    useEffect(()=>{
        const fetchShippingCost=async()=>{
            const Body=
                { 
                 "shipment_category": "B2C", 
                 "payment_type": "Prepaid", 
                 "pickup_pincode": 125037, 
                 "destination_pincode": pincode, 
                 "shipment_invoice_amount": subtotal, 
                 "risk_type": "", 
                 "box_details": [ 
                             { 
                                 "each_box_dead_weight": deadWeight, 
                                 "each_box_length": 15, 
                                 "each_box_width": deadWeight*8, 
                                 "each_box_height": 25, 
                                 "box_count": 1 
                             } 
                         ] 
                } 

            const body={  
                body:Body
            }

            console.log(body,'body v ')
            
            const response=await axios.post(`${BACKEND_URL}/getShippingCosts`,body)

            if(response.status=200){
                setShippingCost(response.data.courier_charge)
                console.log(response.data.courier_charge)
            }
            else{
                console.log(response.message,'shipping rates')
            }
        }

        if (subtotal > 0 && deadWeight > 0) {
            fetchShippingCost();
        }
    },[pincode, deadWeight, subtotal])


    
    
    console.log(deadWeight,'dead weight')

    useEffect(() => {
      const total = cart.reduce((acc, product) => {
        return acc + (product.product.discountPrice * product.quant);
      }, 0);
      setSubTotal(total);
     }, [cart]);
    const handleRemoveFromCart=(productId)=>{
       removeFromCart(productId)
    }
   
     const states = [
    'Andhra Pradesh','Arunachal Pradesh','Assam','Bihar','Chhattisgarh','Goa','Gujarat','Haryana','Himachal Pradesh','Jharkhand','Karnataka','Kerala','Madhya Pradesh','Maharashtra',
    'Manipur','Meghalaya','Mizoram','Nagaland','Odisha','Punjab','Rajasthan','Sikkim','Tamil Nadu','Telangana','Tripura','Uttar Pradesh','Uttarakhand','West Bengal','Delhi','Jammu and Kashmir',
    'Ladakh',
  ];

  
    
  
   

    
  return (
    <div className='cart-container'>
        <div className='page-info'>
            <h1>Your Cart</h1>
            <div className='page-info-navigation'>
                <div className='page-info-navigation-icons' onClick={()=>{navigate('/')}}><FontAwesomeIcon icon={faHouse}></FontAwesomeIcon></div>
                
                
            </div>
        </div>
    <div className='cart'>
        {cart.length === 0 ? (
                    <div className="empty-cart-message">
                        <p>No products in the cart. <Link to='/shop'>Continue to Shop.</Link></p>
                    </div>
                ) : (
        
        <table>
           <thead>
            <tr>
                <th>Remove</th>
                <th>Product image</th>
                <th>Product name</th>
                <th>Unit price</th>
                <th>Quantity</th>
                <th>Total</th>
            </tr>
           </thead>
           <tbody>
            
            {cart.map(product=>(
                <tr>
                    <td>
                        <button onClick={()=>handleRemoveFromCart(product.product._id)} style={{backgroundColor:'#fff',color:'red'}}>X</button>
                    </td>
                    <td><div className='product-title-image' ><img src={product.product.imageUrl} className='cart-image' /></div></td>
                    <td><div className='product-title-image' > {product.product.title}</div></td>
                    <td ><div className='unit-price'><FontAwesomeIcon icon={faIndianRupeeSign} />{product.product.discountPrice}</div></td>
                    <td >
                        <div className='quantity-controls'>
                            <button onClick={() => decrementQuantity(product.product._id)}>-</button>
                            <p>{product.quant}</p>
                            <button onClick={() => incrementQuantity(product.product._id)}>+</button>
                             
                             
                        </div>
                        </td>
                        
                    <td><div className='unit-price'><FontAwesomeIcon icon={faIndianRupeeSign} />{product.product.discountPrice*product.quant}</div></td>
                </tr>
            ))}

           </tbody>
        </table>
    )}
        
        <div className='cart-totals'>
            <h1>Cart Total</h1>
            <h2><FontAwesomeIcon icon={faIndianRupeeSign}/>{subtotal} INR</h2>
            <p>Shipping and discounts will be calculated at checkout</p>
            <button onClick={handleCheckoutNavigation}>Check Out</button>
        </div>
        
    </div>
    <Navbar isHomePage={false}/>
    </div>
  )
}

export default Cart